import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Api from "../../api/api";
import { useAuth } from "../../context/auth";
import avatar from "../../assets/avatar.jpg";
import { FaTrash } from "react-icons/fa";
interface Message {
  _id: string;
  text: string;
  sender: { _id: string; fname: string; lname: string; avatar?: string };
  receiver: { _id: string; fname: string; lname: string };
  createdAt: string;
}

interface Conversation {
  _id: string;
  participants: {
    _id: string;
    fname: string;
    lname: string;
    avatar?: string;
  }[];
  lastMessage: string;
  updatedAt: string;
}

const MessagesPage: React.FC = () => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedConversation, setSelectedConversation] =
    useState<Conversation | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { user: isUser } = useAuth();

  useEffect(() => {
    fetchConversations();
  }, []);
  useEffect(() => {
    fetchMessages();
  }, [selectedConversation]);
  const fetchConversations = async () => {
    setIsLoading(true);
    try {
      const res = await Api.getConversations();
      if (res.status === 200 && Array.isArray(res.data.data)) {
        const sortedConversations = [...res.data.data].sort((a, b) => {
          return (
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
        });
        setConversations(sortedConversations);
        if (sortedConversations.length > 0) {
          const mostRecentConversation = sortedConversations[0];
          setSelectedConversation(mostRecentConversation);
        }
      } else {
        setConversations([]);
      }
    } catch (error) {
      console.error("Error fetching conversations:", error);
      setConversations([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMessages = async (
    conversationId: any = selectedConversation?._id,
  ) => {
    try {
      console.log(conversationId);
      if (!conversationId) return;
      const res = await Api.getMessages({ conversationId });

      console.log("res", res.data.data);
      if (res.status === 200) {
        setMessages(res.data.data);
        setSelectedConversation(
          conversations.find(convo => convo._id === conversationId) || null,
        );
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      setMessages([]);
    }
  };
  const handleDeleteMessage = async (messageId: string) => {
    try {
      const res = await Api.deleteMessage({ messageId: messageId });

      if (res.status === 200) {
        setMessages(messages.filter(message => message._id !== messageId));
      }
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };
  const handleSendMessage = async () => {
    console.log(selectedConversation);
    if (newMessage.trim() && selectedConversation) {
      const receiver = selectedConversation.participants.find(
        participant => participant._id !== isUser?._id,
      );

      if (!receiver) {
        console.error("Receiver not found in the conversation");
        return;
      }

      try {
        const res = await Api.sendMessage({
          receiverId: receiver._id,
          text: newMessage,
        });

        if (res.status === 200 || res.status === 201) {
          setNewMessage("");
          fetchMessages(selectedConversation._id); // Reload messages after sending
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };
  return (
    <>
      <Header />
      <div className="min-h-screen flex bg-gray-100">
        {/* Left Side - Conversations List */}
        <div className="w-1/3 bg-white shadow-md border-r border-gray-200 overflow-y-auto">
          <div className="p-4">
            <h2 className="sm:text-md md:text-lg font-semibold mb-4">
              Conversations
            </h2>
            {isLoading ? (
              <p>Loading conversations...</p>
            ) : (
              conversations.map(conversation => (
                <div
                  key={conversation._id}
                  className={`p-4 mb-2 cursor-pointer border-b border-gray-200 ${
                    selectedConversation?._id === conversation._id
                      ? "bg-blue-100"
                      : "bg-white"
                  } hover:bg-blue-50`}
                  onClick={() => fetchMessages(conversation._id)}
                >
                  {conversation.participants
                    .filter(participant => participant._id !== isUser?._id)
                    .map(participant => (
                      <div
                        key={participant._id}
                        className="flex items-center sm:gap-1.5 md:gap-3"
                      >
                        <img
                          src={participant.avatar || avatar}
                          alt="avatar"
                          className="sm:w-7 sm:h-7 md:w-8 md:h-8 rounded-full"
                        />
                        <p className="font-bold sm:text-sm md:text-lg text-gray-800">
                          {`${participant.fname} ${participant.lname}`}
                        </p>
                      </div>
                    ))}
                  <p className="text-sm text-gray-500 truncate">
                    {conversation.lastMessage}
                  </p>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Right Side - Message History */}
        <div className="w-2/3 p-6 flex flex-col">
          {selectedConversation ? (
            <>
              <div className="flex-1 overflow-y-auto mb-4">
                {messages.length > 0 ? (
                  messages.map(message => (
                    <div
                      key={message._id}
                      className={`p-3 mb-3 max-w-lg ${
                        message.sender._id === isUser?._id
                          ? "bg-blue-500 text-white text-right self-end"
                          : "bg-gray-200 text-left self-start"
                      } rounded-lg`}
                    >
                      <div className="flex items-center gap-2">
                        <img
                          src={message.sender.avatar || avatar}
                          alt="avatar"
                          className="w-8 h-8 rounded-full"
                        />
                        <p>{message.text}</p>
                      </div>
                      <p className="text-xs mt-1 text-gray-1000">
                        {new Date(message.createdAt).toLocaleString()}
                      </p>
                      {message.sender._id === isUser?._id && (
                        <button
                          onClick={() => handleDeleteMessage(message._id)}
                          className="top-0 right-0 mt-2 mr-2 text-red-600"
                        >
                          <FaTrash />
                        </button>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No messages in this conversation.</p>
                )}
              </div>
              <div className="flex">
                <input
                  type="text"
                  value={newMessage}
                  onChange={e => setNewMessage(e.target.value)}
                  className="flex-1 border border-gray-300 p-2 rounded-l-lg"
                  placeholder="Type a message..."
                />
                <button
                  onClick={handleSendMessage}
                  className="bg-blue-500 text-white px-4 rounded-r-lg"
                >
                  Send
                </button>
              </div>
            </>
          ) : (
            <p>Select a conversation to view messages.</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MessagesPage;
