import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import Search from "../Search";
import { ISelectOption } from "../../types/common";
import { countries } from "../../utils/constant";
import { IoIosClose } from "react-icons/io";
import Api from "../../api/api";
import { useLocation } from "react-router-dom";
const Filters: React.FC<{
  items: any;
  search: string;
  setFilteredItems: React.Dispatch<React.SetStateAction<any[]>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  selectedCategories: any;
  setSelectedCategories: React.Dispatch<any>;
  selectedCountries: any;
  selectedLocations?: any;
  setSelectedCountries: React.Dispatch<any>;
  years: { min: string | number; max: string | number };
  filteredItems?: any;
  setYears: React.Dispatch<
    React.SetStateAction<{
      min: string | number;
      max: string | number;
    }>
  >;
  prices: { min: string | number; max: string | number };
  setPrices: React.Dispatch<
    React.SetStateAction<{
      min: string | number;
      max: string | number;
    }>
  >;

  selectedCurrency?: string;
  convertedPrice?: any;
  convertedPrices?: any;
  currency?: any;
  exchangeRates: any;
}> = ({
  setFilteredItems,
  search,
  filteredItems,
  setSearch,
  selectedLocations,
  selectedCategories,
  setSelectedCategories,
  selectedCountries,
  setSelectedCountries,
  years,
  setYears,
  prices,
  setPrices,
  items,
  convertedPrice,
  convertedPrices,
  currency,
  exchangeRates,
  selectedCurrency,
}) => {
  const location = useLocation();
  const q = new URLSearchParams(location.search);
  const hasSeller = q.get("seller");
  const query = q.get("q") || "";
  const [categories, setCategories] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState(query);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  async function getAllCategories() {
    const res = await Api.getCategories();
    if (res.status == 200) {
      const temp = res.data.data.map((category: any) => ({
        label: category.name,
        value: category._id,
      }));
      setCategories(
        temp.sort((a: any, b: any) => {
          return a.label.localeCompare(b.label);
        }),
      );
    }
  }
  useEffect(() => {
    getAllCategories();
  }, []);

  const handleSearch = async () => {
    if (searchQuery.trim()) {
      const response = await Api.searchItems(searchQuery);
      if (response?.status === 200 && Array.isArray(response.data.data)) {
        setFilteredItems(response.data.data);
        setSearchResults(response.data.data);
        applyFilters(response.data.data);
      } else {
        setFilteredItems([]);
      }
    }
  };
  useEffect(() => {
    applyFilters();
  }, [
    search,
    selectedCategories,
    selectedCountries,
    years,
    prices,
    selectedLocations,
    selectedCurrency,
    items,
    currency,
  ]);
  useEffect(() => {
    if (query) {
      setSearchQuery(query);
      handleSearch();
    }
  }, [query, items]);
  const handleSearchInputChange = (value: string) => {
    setSearchQuery(value);
  };

  const handleSearchKeyPress = async (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      await handleSearch();
    }
  };
  const convertCurrency = (
    amount: string | number,
    fromCurrency: string,
    toCurrency: string,
  ): number => {
    if (
      !exchangeRates ||
      !exchangeRates[fromCurrency] ||
      !exchangeRates[toCurrency]
    ) {
      return typeof amount === "number" ? amount : parseFloat(amount);
    }

    const convertedAmount =
      ((typeof amount === "number" ? amount : parseFloat(amount)) /
        exchangeRates[fromCurrency]) *
      exchangeRates[toCurrency];

    return parseFloat(convertedAmount.toFixed(2));
  };
  const applyFilters = (itemsToFilter: any[] = items) => {
    let filtered: any = [...itemsToFilter];

    const keywords = search.trim().toLowerCase().split(/\s+/);
    if (keywords.length > 0) {
      filtered = filtered.filter(item => {
        return keywords.some(
          keyword =>
            item?.name?.toLowerCase().includes(keyword) ||
            item?.year?.toString().includes(keyword) ||
            item?.category?.toLowerCase().includes(keyword) ||
            item?.country?.toLowerCase().includes(keyword) ||
            item?.sellerName?.toLowerCase().includes(keyword),
        );
      });
    }

    if (selectedCategories.length > 0) {
      filtered = filtered.filter(item =>
        selectedCategories.some((category: any) =>
          item.categories.some(
            (itemCategory: any) => itemCategory._id === category.value,
          ),
        ),
      );
    }

    if (selectedCountries.length > 0) {
      filtered = filtered.filter(item =>
        selectedCountries.some((country: any) =>
          item.country.includes(country.value),
        ),
      );
    }
    // Apply year filter
    if (years.min || years.max) {
      filtered = filtered.filter(item => {
        const itemYear = parseInt(item.year, 10);
        return (
          (!years.min || itemYear >= parseInt(years.min.toString(), 10)) &&
          (!years.max || itemYear <= parseInt(years.max.toString(), 10))
        );
      });
    }
    /*
    if (prices.min || prices.max) {
      filtered = filtered.filter(item => {
        const convertedItem = convertedPrices?.find(cp => cp.id === item._id);
        if (convertedItem) {
          const itemPrice = convertedItem.convertedPrice;

          if (isNaN(itemPrice)) {
            console.warn("Excluding item due to invalid price", item);
            return false;
          }

          return (
            (!prices.min || itemPrice >= parseFloat(prices.min.toString())) &&
            (!prices.max || itemPrice <= parseFloat(prices.max.toString()))
          );
        }
        return true;
      });
    }*/
    if (prices.min || prices.max) {
      filtered = filtered.filter(item => {
        const itemPrice = convertCurrency(item.price, item.currency, currency);

        return (
          (!prices.min || itemPrice >= parseFloat(prices.min.toString())) &&
          (!prices.max || itemPrice <= parseFloat(prices.max.toString()))
        );
      });
    }
    if (selectedLocations && selectedLocations.length > 0) {
      filtered = filtered.filter(item =>
        selectedLocations.includes(item.uid?.country),
      );
    }

    setFilteredItems(filtered);
  };
  return (
    <div className="mb-10 mt-4 max-w-[1260px]">
      <div className="md:flex gap-3 lg:flex-row max-lg:flex-wrap  items-end">
        <Search
          value={searchQuery}
          onChange={e => handleSearchInputChange(e.target.value)}
          onKeyDown={handleSearchKeyPress}
          onSearchIconClick={handleSearch}
        />

        <div className="flex items-center gap-3 w-full  sm:w-full">
          <Select
            title="Category"
            selected={selectedCategories}
            setSelected={setSelectedCategories}
            options={categories}
          />
          <Select
            title="Country"
            selected={selectedCountries}
            setSelected={setSelectedCountries}
            options={[...countries]}
          />
        </div>
        <div className="flex items-center gap-3 sm:w-full ">
          <Year
            value={years}
            setValue={setYears}
          />
          <Price
            value={prices}
            setValue={setPrices}
            selectedCurrency={selectedCurrency}
          />
        </div>
      </div>
      <div className="mt-2 text-primary">Items: {filteredItems?.length}</div>
      <div className="mt-4 flex gap-3 items-center flex-wrap">
        {selectedCategories.map((itm, idx) => (
          <div
            key={idx}
            className="rounded-xl border border-primary px-2.5 py-1.5 flex items-center gap-1"
          >
            <span className="text-sm text-primary">{itm.label}</span>
            <IoIosClose
              size={22}
              className="cursor-pointer"
              onClick={() =>
                setSelectedCategories(prev =>
                  prev?.filter(item => item.value !== itm.value),
                )
              }
            />
          </div>
        ))}
        {selectedCountries.map((itm, idx) => (
          <div
            key={idx}
            className="rounded-xl border border-primary px-2.5 py-1.5 flex items-center gap-1"
          >
            <span className="text-sm text-primary">{itm.label}</span>
            <IoIosClose
              size={22}
              className="cursor-pointer"
              onClick={() =>
                setSelectedCountries(prev =>
                  prev?.filter(item => item.value !== itm.value),
                )
              }
            />
          </div>
        ))}
        {(years?.max || years?.min) && (
          <div className="rounded-xl border border-primary px-2.5 py-1.5 flex items-center gap-1">
            <span className="text-sm text-primary">
              {years.min} - {years.max}
            </span>
            <IoIosClose
              size={22}
              className="cursor-pointer"
              onClick={() => setYears({ min: "", max: "" })}
            />
          </div>
        )}
        {(prices?.max || prices?.min) && (
          <div className="rounded-xl border border-primary px-2.5 py-1.5 flex items-center gap-1">
            <span className="text-sm text-primary">
              {prices.min} - {prices.max}
            </span>
            <IoIosClose
              size={22}
              className="cursor-pointer"
              onClick={() => setPrices({ min: "", max: "" })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const Select = ({
  title,
  options,
  selected,
  setSelected,
}: {
  title: string;
  options: ISelectOption[];
  selected: any;
  setSelected: React.Dispatch<any>;
}) => {
  return (
    <div className="grid place-items-center gap-2 w-full sm:max-w-[300px] md:max-w-[200px]">
      <span className="text-primary font-semibold">{title}</span>
      <MultiSelect
        value={selected}
        onChange={setSelected}
        hasSelectAll={false}
        options={options}
        labelledBy={title}
        className="multi_select w-full"
      />
    </div>
  );
};

const Year: React.FC<{
  value: { min: string | number; max: string | number };
  setValue: React.Dispatch<
    React.SetStateAction<{
      min: string | number;
      max: string | number;
    }>
  >;
}> = ({ value, setValue }) => {
  return (
    <div className="grid place-items-center gap-2 w-full sm:max-w-[150px] md:max-w-[200px]">
      <span className="text-primary font-semibold">Year</span>
      <div className="flex items-center justify-center gap-1.5 md:gap-3 w-full rounded-md bg-white border border-primary px-2 md:px-3 py-2 text-sm">
        <input
          type="number"
          value={value.min}
          placeholder="Min"
          min="0"
          onChange={e => setValue(prev => ({ ...prev, min: e.target.value }))}
          className="bg-[#F5F5F5] text-center w-[50px] font-semibold  md:text-base no-spinner md:w-[50px] px-0 outline-none rounded border border-primary"
        />
        <span className="font-semibold">-</span>
        <input
          type="number"
          value={value.max}
          placeholder="Max"
          min={value.min}
          onChange={e => setValue(prev => ({ ...prev, max: e.target.value }))}
          className="bg-[#F5F5F5] text-center w-[50px] font-semibold  md:text-base no-spinner md:w-[50px] px-0 outline-none rounded border border-primary"
        />
      </div>
    </div>
  );
};

const Price: React.FC<{
  value: { min: string | number; max: string | number };
  setValue: React.Dispatch<
    React.SetStateAction<{
      min: string | number;
      max: string | number;
    }>
  >;
  selectedCurrency?: string;
}> = ({ value, setValue, selectedCurrency }) => {
  const currencySymbol =
    selectedCurrency === "USD" ? "$" : selectedCurrency === "EUR" ? "€" : "₪"; // Add more symbols as needed

  return (
    <div className="grid place-items-center gap-2 w-full sm:max-w-[100px] md:max-w-[200px]">
      <span className="text-primary font-semibold">Price</span>
      <div className="flex items-center md:text-base  justify-center gap-1.5 md:gap-3 w-full rounded-md bg-white border border-primary px-2 md:px-3 py-2 text-sm">
        <span>{currencySymbol}</span>
        <input
          type="number"
          placeholder="Min"
          value={value.min}
          min={0}
          style={{ MozAppearance: "textfield", WebkitAppearance: "none" }}
          onChange={e => setValue(prev => ({ ...prev, min: e.target.value }))}
          className="bg-[#F5F5F5] md:text-base text-center font-semibold no-spinner w-[50px] md:w-[60px] px-1 outline-none rounded border border-primary"
        />
        <span className="font-semibold">-</span>
        <input
          type="number"
          placeholder="Max"
          value={value.max}
          min={value.min || 1}
          style={{ MozAppearance: "textfield", WebkitAppearance: "none" }}
          onChange={e => setValue(prev => ({ ...prev, max: e.target.value }))}
          className="bg-[#F5F5F5]  md:text-base text-center font-semibold no-spinner w-[50px] md:w-[60px] px-1 outline-none rounded border border-primary"
        />
      </div>
    </div>
  );
};
export default Filters;
