import "./App.css";
import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { JwtPayload, jwtDecode } from "jwt-decode";
import {
  PROTECTED_ROUTES,
  AUTH_ROUTES,
  ROUTES,
  ADMIN_ROUTES,
} from "./utils/routes";
import Auth from "./authRoutes";
import Protected from "./protectedRoute";
import { AuthProvider } from "./context/auth";
import Cookies from "js-cookie";
import { REACT_APP_KEY_NAME } from "./utils/constant";
import AdminProtected from "./adminRoute";

function App() {
  const location = useLocation();

  const verifyToken = () => {
    const token = Cookies.get(REACT_APP_KEY_NAME);
    if (token) {
      const decode_token: JwtPayload = jwtDecode(token);
      if (
        (decode_token.exp && decode_token.exp * 1000 < new Date().getTime()) ||
        Cookies.get(REACT_APP_KEY_NAME) === undefined ||
        localStorage.getItem(REACT_APP_KEY_NAME) === "undefined"
      ) {
        Cookies.remove(REACT_APP_KEY_NAME);
        localStorage.removeItem(REACT_APP_KEY_NAME);
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    verifyToken();
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <AuthProvider>
        {ROUTES.map((route, index) => (
          <Route
            exact
            key={index}
            {...route}
          />
        ))}

        {AUTH_ROUTES.map((route, index) => (
          <Auth
            exact
            key={index}
            {...route}
          />
        ))}

        {PROTECTED_ROUTES.map((route, index) => (
          <Protected
            exact
            key={index}
            {...route}
          />
        ))}

        {ADMIN_ROUTES.map((route, index) => (
          <AdminProtected
            exact
            key={index}
            {...route}
          />
        ))}
      </AuthProvider>

      <Toaster
        position="top-center"
        reverseOrder={false}
      />
    </>
  );
}

export default App;

