import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Api from "../../api/api";
import { useAuth } from "../../context/auth";
import { CiSettings } from "react-icons/ci";
import { links } from "../seller";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import toast from "react-hot-toast";
import Input from "../../common/Input";
import Button from "../../common/Button";

const PasswordChange: React.FC = () => {
  const location = useLocation();
  const [data, setData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const { user } = useAuth();
  const [isShow, setIsShow] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { value, name } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  function validatePassword(password: any) {
    const validations = {
      length: password.length >= 6,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      symbol: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      isValid: true,
    };
    validations.isValid = Object.values(validations).every(Boolean);
    return validations;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (data.newPassword !== data.confirmPassword) {
      return toast.error("Confirm Password doesn't match");
    }

    setIsDisable(true);
    try {
      await Api.changePassword({
        ...data,
        ...user,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <div className="w-full flex gap-4 md:gap-6 flex-wrap">
              <div className="w-[192px] relative">
                <button
                  onClick={() => setIsShow(prev => !prev)}
                  className="text-sm w-full rounded-lg justify-center bg-primary text-white flex items-center gap-2 py-3 px-5"
                >
                  <CiSettings
                    size={20}
                    fill="#fff"
                  />
                  Account
                </button>
                {isShow && (
                  <div className="rounded-xl bg-white mt-2 py-2 absolute left-0 right-0 top-11 z-50">
                    {links?.map((itm, idx) => (
                      <div
                        key={idx}
                        className="flex items-center gap-2 py-3 px-5"
                      >
                        <itm.icon
                          size={16}
                          fill="var(--primary)"
                        />
                        <Link
                          to={itm.link}
                          className={`text-sm text-primary hover:underline ${location.pathname === itm.link ? "underline" : ""}`}
                        >
                          {itm.title}
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="w-full md:w-[calc(100%_-_217px)]">
                <div className="bg-white rounded-2xl p-4 md:p-6">
                  <div className="flex flex-wrap-reverse gap-4 items-center">
                    <div className="md:pl-8 md:flex-1 max-md:w-full">
                      <h3 className="text-xl font-semibold mb-3">
                        Password Requirement
                      </h3>
                      <p className="mb-1.5">Password must contain:</p>
                      <div className="flex items-center gap-2 mb-1">
                        {validatePassword(data.newPassword).length ? (
                          <FaCheckCircle fill="var(--primary)" />
                        ) : (
                          <MdCancel fill="red" />
                        )}
                        <p>atleast 6 characters</p>
                      </div>
                      <div className="flex items-center gap-2 mb-1">
                        {validatePassword(data.newPassword).uppercase ? (
                          <FaCheckCircle fill="var(--primary)" />
                        ) : (
                          <MdCancel fill="red" />
                        )}
                        <p>atleast 1 uppercase letter (A-Z)</p>
                      </div>
                      <div className="flex items-center gap-2 mb-1">
                        {validatePassword(data.newPassword).lowercase ? (
                          <FaCheckCircle fill="var(--primary)" />
                        ) : (
                          <MdCancel fill="red" />
                        )}
                        <p>atleast 1 lowercase letter (a-z)</p>
                      </div>
                      <div className="flex items-center gap-2 mb-1">
                        {validatePassword(data.newPassword).number ? (
                          <FaCheckCircle fill="var(--primary)" />
                        ) : (
                          <MdCancel fill="red" />
                        )}
                        <p>atleast 1 number (0-9)</p>
                      </div>
                      <div className="flex items-center gap-2 mb-1">
                        {validatePassword(data.newPassword).symbol ? (
                          <FaCheckCircle fill="var(--primary)" />
                        ) : (
                          <MdCancel fill="red" />
                        )}
                        <p>atleast 1 symbol (e.g @)</p>
                      </div>
                    </div>
                    <div className="md:flex-1 max-md:w-full">
                      <form
                        onSubmit={handleSubmit}
                        className="w-full bg-white px-4 md:px-6 py-8 md:py-10 rounded-xl md:rounded-2xl"
                      >
                        <Input
                          type="password"
                          title="oldPassword*"
                          name="oldPassword"
                          value={data?.oldPassword}
                          onChange={handleInputChange}
                          placeholder="Enter Old Password"
                          required
                        />
                        <Input
                          type="password"
                          title="New Password*"
                          name="newPassword"
                          value={data?.newPassword}
                          onChange={handleInputChange}
                          placeholder="Enter New Password"
                          required
                        />
                        <Input
                          type="password"
                          title="Confirm Password*"
                          name="confirmPassword"
                          value={data?.confirmPassword}
                          onChange={handleInputChange}
                          placeholder="Enter Confirm Password"
                          required
                        />
                        <br />
                        <Button
                          type="submit"
                          disabled={
                            isDisable ||
                            !validatePassword(data.newPassword).isValid
                          }
                        >
                          Change Password
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PasswordChange;
