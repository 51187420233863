import React from "react";
import { Link } from "react-router-dom";
import { IoIosCheckmarkCircle } from "react-icons/io";
import Avatar from "../../assets/avatar.jpg";

const Seller = params => {
  return (
    <Link
      to={`/seller/${params?.itm?._id}`}
      className="block bg-white rounded-xl p-3 h-full hover:transition-all hover:shadow-[#00000033_0px_0px_10px_-2px]"
    >
      <img
        className="w-[20px] rounded"
        src={`https://flagcdn.com/32x24/${params?.itm?.country?.toLowerCase()}.png`}
        alt={`${params?.itm?.country} flag`}
      />

      <div className="flex flex-col items-center gap-2">
        <div className="h-[10rem] w-full sm:w-[8rem] md:w-[12rem] mx-auto my-2 overflow-hidden">
          <img
            className="w-full h-full object-contain"
            src={params?.itm?.avatar || Avatar}
            alt="Avatar"
          />
        </div>
        <div>
          <div className="mb-1 flex items-center gap-1 justify-center">
            <p className="font-semibold">
              {params?.itm?.fname + " " + params?.itm?.lname}
            </p>
            <IoIosCheckmarkCircle fill="#00ab07" />
          </div>
          <p className="text-center text-xs">{params?.itm?.about}</p>
        </div>
      </div>
    </Link>
  );
};

export default Seller;
