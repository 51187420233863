import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/Admin/AdminHeader";
import Footer from "../../common/Footer";
import Search from "../../components/Search";
import ReactPaginate from "react-paginate";
import AdminSeller from "../../components/Admin/AdminSeller";
import Dots from "../../assets/dots.svg";
import Api from "../../api/api";
import toast from "react-hot-toast";

const AdminSellers: React.FC = () => {
  const [sellers, setSellers] = useState<any[]>([]);
  const [filteredSellers, setFilteredSellers] = useState<any[]>([]); // To store filtered/sorted sellers
  const [itemOffset, setItemOffset] = useState(0);
  const [search, setSearch] = useState<string | undefined>("");
  const [status, setStatus] = useState("all"); // Filter by status: all, approved, pending
  const [sort, setSort] = useState("date_asc"); // Sorting criteria

  const perPage = 24;
  const pageCount = Math.ceil(filteredSellers.length / perPage);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * perPage) % filteredSellers.length;
    setItemOffset(newOffset);
  };

  async function getAllUsers() {
    const res: any = await Api.getUsersByAdmin("seller");
    if (res.status === 200) {
      console.log(res.data.data.users);
      setSellers(res.data.data.users);
      setFilteredSellers(res.data.data.users); // Set initial data for filtered sellers
    }
  }

  // Effect to fetch users once
  useEffect(() => {
    getAllUsers();
  }, []);

  // Effect to apply filtering and sorting
  useEffect(() => {
    let updatedSellers = [...sellers];

    // Apply search filter
    if (search) {
      updatedSellers = updatedSellers.filter(seller =>
        `${seller.fname} ${seller.lname}`
          .toLowerCase()
          .includes(search.toLowerCase()),
      );
    }

    // Apply status filter (approved/pending)
    if (status !== "all") {
      const isVerified = status === "approved";
      updatedSellers = updatedSellers.filter(
        seller => seller.isVerifiedByAdmin === isVerified,
      );
    }

    // Apply sorting
    if (sort === "date_asc") {
      updatedSellers.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      );
    } else if (sort === "date_desc") {
      updatedSellers.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );
    } else if (sort === "items_asc") {
      updatedSellers.sort((a, b) => a.totalItems - b.totalItems);
    } else if (sort === "items_desc") {
      updatedSellers.sort((a, b) => b.totalItems - a.totalItems);
    }

    // Set the filtered and sorted sellers
    setFilteredSellers(updatedSellers);
  }, [search, status, sort, sellers]);
  const handleVerify = async sellerId => {
    try {
      const res = await Api.toggleVerifyByAdmin(sellerId);
      if (res.status === 200) {
        toast.success("Seller verified successfully.");
        setSellers(prevSellers =>
          prevSellers.map(seller =>
            seller._id === sellerId
              ? {
                  ...seller,
                  isVerified: !seller.isVerifiedByAdmin,
                  isVerifiedByAdmun: !seller.isVerifiedByAdmin,
                }
              : seller,
          ),
        );
      }
    } catch (error) {
      toast.error("Failed to verify seller.");
    }
  };

  const handleUnverify = async sellerId => {
    try {
      const res = await Api.toggleVerifyByAdmin(sellerId);
      if (res.status === 200) {
        toast.success("Seller unverified successfully.");
        setSellers(prevSellers =>
          prevSellers.map(seller =>
            seller._id === sellerId
              ? { ...seller, isVerified: !seller.isVerified }
              : seller,
          ),
        );
      }
    } catch (error) {
      toast.error("Failed to unverify seller.");
    }
  };
  const handleUpdateSeller = async (sellerId, updatedData) => {
    try {
      const res = await Api.updateProfileByAdmin({
        id: sellerId,
        ...updatedData,
      });
      if (res.status === 200) {
        toast.success("Seller updated successfully.");

        setSellers(prevSellers =>
          prevSellers.map(seller =>
            seller._id === sellerId ? { ...seller, ...updatedData } : seller,
          ),
        );
      } else {
        toast.error("Failed to update seller.");
      }
    } catch (error) {
      toast.error("An error occurred while updating the seller.");
    }
  };

  const handleDelete = async sellerId => {
    try {
      const res = await Api.deleteUserByAdmin(sellerId);
      if (res.status === 200) {
        toast.success("Seller deleted successfully.");
        setSellers(prevSellers =>
          prevSellers.filter(seller => seller._id !== sellerId),
        );
      }
    } catch (error) {
      toast.error("Failed to delete seller.");
    }
  };
  return (
    <>
      <AdminHeader />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            {/* Search and Filter Row */}

            <div className="flex items-center justify-between gap-4 mb-4">
              {/* Search Input */}
              <Search
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
              <b>Total Sellers: {sellers.length}</b>
              {/* Sort by Date Joined */}
              <div className="flex items-center">
                <button
                  className="flex items-center gap-1 text-sm font-medium cursor-pointer"
                  onClick={() =>
                    setSort(sort === "date_asc" ? "date_desc" : "date_asc")
                  }
                >
                  Date Joined
                  {sort === "date_asc" ? (
                    <span className="ml-1">▲</span>
                  ) : (
                    <span className="ml-1">▼</span>
                  )}
                </button>
              </div>
              {/* Sort by Number of Items */}
              <div className="flex items-center">
                <button
                  className="flex items-center gap-1 text-sm font-medium cursor-pointer"
                  onClick={() =>
                    setSort(sort === "items_asc" ? "items_desc" : "items_asc")
                  }
                >
                  Number of Items
                  {sort === "items_asc" ? (
                    <span className="ml-1">▲</span>
                  ) : (
                    <span className="ml-1">▼</span>
                  )}
                </button>
              </div>
              {/* Filter by Status */}
              <div className="flex items-center">
                <label className="mr-2">Status: </label>
                <select
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                  className="rounded-md border border-gray-300 px-2 py-1"
                >
                  <option value="all">All</option>
                  <option value="approved">Approved</option>
                  <option value="pending">Pending</option>
                </select>
              </div>
            </div>
            {/* Sellers list */}
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {filteredSellers
                .slice(itemOffset, itemOffset + perPage)
                .map((itm, idx) => (
                  <AdminSeller
                    key={idx}
                    itm={itm}
                    onVerify={handleVerify}
                    onUnverify={handleUnverify}
                    onDelete={handleDelete}
                    onUpdate={handleUpdateSeller}
                  />
                ))}
            </div>
            {/* Pagination */}
            <div className="mt-8">
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName="flex flex-wrap justify-center mt-4"
                pageClassName="mx-2"
                pageLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
                previousClassName="mx-2"
                previousLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
                nextClassName="mx-2"
                nextLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
                breakClassName="mx-2"
                breakLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
                activeClassName="bg-slate-200 rounded-full"
                activeLinkClassName="font-semibold bg-slate-200"
              />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default AdminSellers;
