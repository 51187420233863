import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  PortableText,
  PortableTextProps,
  PortableTextBlock,
} from "@portabletext/react";
import BlogImages from "./BlogImages";
import YoutubeEmbed from "./YoutubeEmbed";

interface Post {
  title: string;
  content: PortableTextBlock[];
  coverImageUrl?: string;
}

const BlogPost: React.FC = () => {
  const [post, setPost] = useState<Post | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { slug, source } = useParams<{ slug: string; source: string }>();

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        const response = await fetch(
          `/.netlify/functions/fetch-single-post?slug=${slug}&source=${source}`,
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const postData: Post = await response.json();
        setPost(postData);
      } catch (error) {
        console.error("Error fetching post:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPostData();
  }, [slug, source]);

  const linkSerializer = (props: any) => {
    const { value } = props;
    if (value.href) {
      if (
        value.href.startsWith("https://www.youtube.com") ||
        value.href.startsWith("https://youtu.be")
      ) {
        return <YoutubeEmbed url={value.href} />;
      } else {
        return (
          <a
            href={value.href}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            {props.children}
          </a>
        );
      }
    } else {
      console.warn("Warning: Missing 'href' attribute in link mark", props);
      return <span>{props.children}</span>;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-500"></div>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="text-center py-10">
        <h2 className="text-2xl font-semibold">No post found!</h2>
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-10 px-4">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          {post.coverImageUrl && (
            <div className="flex justify-center items-center h-64">
              <img
                src={post.coverImageUrl}
                alt={post.title}
                className="w-auto h-64 object-contain"
              />
            </div>
          )}
          <div className="p-6 ">
            <div className="flex justify-center items-center">
              <h1 className="text-3xl font-semibold text-gray-800 mb-6">
                {post.title}
              </h1>
            </div>
            <div className="prose max-w-none">
              <PortableText
                value={post.content}
                components={{
                  types: {
                    image: (props: PortableTextProps) => (
                      // @ts-expect-error: Suppress TypeScript error
                      <BlogImages {...props} />
                    ),
                  },
                  marks: {
                    link: linkSerializer,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
