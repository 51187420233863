import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Item from "../components/Items/Item";
import Api from "../api/api";
import { useAuth } from "../context/auth";
import Button from "../common/Button";
import avatar from "../assets/avatar.jpg";
import Modal from "react-modal";
import { RxDropdownMenu } from "react-icons/rx";
import { FaBars } from "react-icons/fa6";
import { FaUserCog } from "react-icons/fa";
import AdminHeader from "../components/Admin/AdminHeader";
Modal.setAppElement("#root");

const CollectionPage = () => {
  const { id } = useParams<any>();
  const { user, isAdmin } = useAuth();
  const history = useHistory();
  const [collection, setCollection] = useState<any>();
  const [items, setItems] = useState<any[]>([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [seller, setSeller] = useState<any>();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const perPage = 48;

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * perPage) % items.length;
    setItemOffset(newOffset);
  };

  async function getSeller(uid: string) {
    const res: any = await Api.getUser(uid);
    if (res.status === 200) {
      setSeller(res.data.data);
    }
  }

  async function getCollectionItems() {
    const params = { collectionId: id, userId: user?._id };
    const res: any = await Api.getCollection(params);
    if (res.status === 200) {
      const fetchedItems: any = res?.data?.data;
      fetchedItems.items.sort((a, b) => {
        if (a.hidden && !b.hidden) return 1;
        if (!a.hidden && b.hidden) return -1;

        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return dateB - dateA;
      });
      getSeller(fetchedItems.user);
      setItems(fetchedItems.items);
      setCollection(fetchedItems);
      setPageCount(Math.ceil(items.length / perPage));
      setNewCollectionName(fetchedItems.name); // Set initial collection name
    }
  }

  useEffect(() => {
    getCollectionItems();
  }, [id, user?._id]);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleHideCollection = async () => {
    const updatedHiddenStatus = !collection.hidden;
    const data = { collectionId: id, hidden: updatedHiddenStatus };
    if (isAdmin()) {
      await Api.updateCollectionAdmin(data);
    } else {
      await Api.updateCollection(data);
    }
    setCollection({ ...collection, hidden: updatedHiddenStatus });
    setIsDropdownOpen(false);
  };

  const handleDeleteCollection = async () => {
    if (isAdmin()) {
      await Api.deleteCollectionAdmin(id);
    } else {
      await Api.deleteCollection(id);
    }
    setIsModalOpen(false);
    history.push("/collections");
  };
  const handleDeleteItem = async (itemId: string) => {
    // Filter out the item to be deleted
    const updatedItems = collection.items.filter(
      (item: any) => item._id !== itemId,
    );

    // Prepare the data to update the collection
    const data = {
      collectionId: id,
      items: updatedItems,
    };

    try {
      if (isAdmin()) {
        await Api.updateCollectionAdmin(data);
      } else {
        await Api.updateCollection(data);
      }
      setCollection((prevCollection: any) => ({
        ...prevCollection,
        items: updatedItems,
      }));
      setItems(updatedItems);
    } catch (error) {
      console.error("Failed to delete item from collection", error);
    }
  };
  const handleNameEdit = async () => {
    const data: any = { collectionId: id, name: newCollectionName };
    if (isAdmin()) {
      await Api.updateCollectionAdmin(data);
    } else {
      await Api.updateCollection(data);
    }
    setIsEditingName(false);
    getCollectionItems();
  };

  const handleAddItems = () => {
    if (isAdmin()) {
      history.push(`/admin/collection/manage/${id}`);
    } else {
      history.push(`/collection/manage/${id}`);
    }
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isAdmin() ? <AdminHeader /> : <Header />}
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <div className="flex justify-between items-center mb-8">
              {/* Profile Information (Left-aligned) */}
              <div className="flex items-center gap-4 max-w-[250px]">
                <div className="bg-white p-3 md:p-4 rounded-2xl border border-black w-full">
                  <div className="flex items-center gap-3">
                    <img
                      src={seller?.avatar || avatar}
                      alt="Seller"
                      className="sm:w-8 sm:h-8 w-14 h-14 md:w-20 md:h-20 rounded-full object-cover"
                    />
                    <div>
                      <p className="text-lg sm:text-md md:text-xl font-semibold mb-1">
                        {seller?.fname} {seller?.lname}
                      </p>
                      <p
                        className="text-sm sm:text-xs md:text-sm md:text-base line-clamp-2"
                        style={{ fontSize: 12 }}
                      >
                        {seller?.about}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-grow flex items-center justify-center relative">
                {isEditingName ? (
                  <input
                    type="text"
                    value={newCollectionName}
                    onChange={e => setNewCollectionName(e.target.value)}
                    onBlur={handleNameEdit}
                    onKeyDown={e => {
                      if (e.key === "Enter") handleNameEdit();
                    }}
                    className="text-center text-2xl md:text-3xl lg:text-4xl font-bold border-b-2 border-gray-400 focus:outline-none focus:border-blue-500 transition-all duration-300 w-full max-w-xs"
                    autoFocus
                  />
                ) : (
                  <p
                    className="text-center text-2xl md:text-3xl lg:text-4xl font-bold cursor-pointer hover:text-blue-500 transition-all duration-300"
                    onClick={() => setIsEditingName(true)}
                  >
                    {collection?.name}
                  </p>
                )}
              </div>

              {user?._id === seller?._id ||
                (isAdmin() && (
                  <div className="relative">
                    <Button
                      className="!w-fit flex items-center gap-2"
                      onClick={handleDropdownToggle}
                    >
                      Manage
                      <FaBars fill="white" />
                    </Button>
                    {isDropdownOpen && (
                      <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                        <button
                          onClick={handleAddItems}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
                        >
                          Add Items
                        </button>
                        <button
                          onClick={() => setIsEditingName(true)}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
                        >
                          Rename Collection
                        </button>
                        <button
                          onClick={handleHideCollection}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
                        >
                          {collection?.hidden
                            ? "Unhide Collection"
                            : "Hide Collection"}
                        </button>
                        <button
                          onClick={handleOpenModal}
                          className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
                        >
                          Delete Collection
                        </button>

                        <Modal
                          isOpen={isModalOpen}
                          onRequestClose={handleCloseModal}
                          contentLabel="Delete Collection"
                          className="bg-white rounded-lg p-6 max-w-lg mx-auto"
                          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
                        >
                          <h2 className="text-xl font-semibold mb-4">
                            Delete Collection
                          </h2>
                          <p>
                            Are you sure you want to delete this collection?
                          </p>
                          <div className="flex justify-end gap-4 mt-6">
                            <button
                              onClick={handleCloseModal}
                              className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={handleDeleteCollection}
                              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                            >
                              Confirm
                            </button>
                          </div>
                        </Modal>
                      </div>
                    )}
                  </div>
                ))}
            </div>

            <p className="text-lg text-gray-500">{items.length} items</p>

            {/* Items Grid */}
            <div className="grid gap-4 grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
              {items
                .slice(itemOffset, itemOffset + perPage)
                .map((item, idx) => (
                  <Item
                    key={item._id || idx}
                    isPinned={item.pinned}
                    isFeatured={item.pinned}
                    isCountry={item.isCountry}
                    isCategory={true}
                    user_data={item.uid}
                    isSeller={isAdmin() ? true : user?._id === item?.uid}
                    name={item.name}
                    uid={item.uid._id}
                    description={item.description}
                    country={item.countries}
                    photos={item.photos}
                    category={item.categories}
                    currency={item.currency}
                    price={item.price}
                    year={item?.year}
                    hidden={item?.hidden}
                    id={item?._id}
                    createdAt={item?.createdAt}
                    updatedAt={item?.createdAt}
                    onPinToggle={() => {}}
                    onDelete={handleDeleteItem}
                    isHiddenTemp={true}
                  />
                ))}
            </div>
          </div>

          <br />
          <div>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="flex flex-wrap justify-center mt-4"
              pageClassName="mx-2"
              pageLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              previousClassName="mx-2"
              previousLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              nextClassName="mx-2"
              nextLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              breakClassName="mx-2"
              breakLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              activeClassName="bg-slate-200 rounded-full "
              activeLinkClassName="font-semibold bg-slate-200"
            />
          </div>
        </div>
      </main>

      <Footer />

      {/* Confirmation Modal for Delete Collection */}
      {isModalOpen && (
        <Modal
          title="Delete Collection"
          onClose={() => setIsModalOpen(false)}
          onConfirm={handleDeleteCollection}
        >
          <p>Are you sure you want to delete this collection?</p>
        </Modal>
      )}
    </>
  );
};

export default CollectionPage;
