import React from "react";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import { LINKS } from "../utils/constant";
import { ISidebarProps } from "../types/common";

const Sidebar: React.FC<ISidebarProps> = ({
  toggle,
  setToggle,
  activeLink,
  user,
  handleLogout,
}) => {
  return (
    <div
      className={`sidebar bg-white lg:hidden max-w-[50%] fixed h-[50vh] top-0 ${toggle ? "right-0" : "right-[-100vw]"} w-screen p-4 z-[9982] transition-all`}
    >
      <div className="w-full flex justify-end">
        <IoMdClose
          size={26}
          fill="var(--primary)"
          className="cursor-pointer"
          onClick={() => [
            setToggle(false),
            // @ts-expect-error ts-ignore
            (document.querySelector("body").style.overflowY = "scroll"),
          ]}
        />
      </div>
      <div className="my-4">
        {LINKS?.map(itm => (
          <Link
            key={itm.path}
            to={itm.path}
            className={`mb-4 text-sm parent-link capitalize w-fit flex items-center gap-4 font-medium ${activeLink(itm.path)}`}
          >
            <itm.icon
              size={18}
              fill="var(--primary)"
            />
            {itm.title}
          </Link>
        ))}
      </div>
      <div className="flex flex-col gap-2 mt-4">
        {!user ? (
          <>
            <Link
              to="/auth/login"
              className="rounded-lg bg-primary text-[#ffffff] max-w-[50%] text-sm text-center px-1 py-1"
            >
              Log in
            </Link>
            <Link
              to="/auth/signup"
              className="rounded-lg bg-primary text-[#ffffff]  max-w-[50%] text-sm text-center px-1 py-1"
            >
              Sign up
            </Link>
          </>
        ) : (
          <>
            <Link
              to={`/seller/${user._id}`}
              className="rounded-lg bg-primary text-[#ffffff]  max-w-[50%] text-sm text-center px-1 py-1"
              onClick={() => setToggle(false)}
            >
              Profile
            </Link>
            <div
              onClick={() => {
                handleLogout();
                setToggle(false);
              }}
              className="cursor-pointer rounded-lg text-primary border border-primary  max-w-[50%] text-sm text-center px-1 py-1"
            >
              Log out
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
