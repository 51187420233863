import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Detail from "../components/Seller/Detail";
import Item from "../components/Items/Item";
import Collection from "../components/Collection";
import { useParams } from "react-router-dom";
import Api from "../api/api";
import { useAuth } from "../context/auth";
import { CiSettings } from "react-icons/ci";
import { FaEyeSlash, FaHeart, FaLock, FaTrash, FaUser } from "react-icons/fa";
import { IoIosInformationCircle } from "react-icons/io";
import { log } from "util";
import FeaturedItems from "./client/featured";
import { REACT_APP_KEY_NAME } from "./../utils/constant";
import MessageModal from "./client/message_modal";
import { FaMessage } from "react-icons/fa6";
import { MdOutlineAddBox } from "react-icons/md";

export const links = [
  { icon: FaUser, title: "Edit Profile", link: `/profile/edit` },
  { icon: FaLock, title: "Change Password", link: `/password/edit` },
  { icon: FaHeart, title: "Favorites", link: `/favorites` },
  { icon: FaEyeSlash, title: "Hiddens", link: `/hidden/all` },
  {
    icon: IoIosInformationCircle,
    title: "Account visibility",
    link: `/account-visibility/edit`,
  },
  { icon: FaTrash, title: "Delete account", link: `#` },
];

const Seller: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const { id } = useParams();
  const [user, setUser] = useState<any>("");
  const { user: isUser } = useAuth();
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<any>([]);
  const [featuredItems, setFeaturedItems] = useState<any>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [collections, setCollections] = useState<any>([]);
  const [favoriteItems, setFavoriteItems] = useState<any[]>([]);
  useEffect(() => {
    getUser();
    getUserItems();
    const count =
      isUser?._id === id
        ? items.length
        : items.filter((item: any) => !item.hidden).length;
    setItemCount(count);
    getAllCollections();
    if (isUser?._id === id) {
      fetchFavoriteItems();
    }
    console.log(user?.visibilitySettings);
  }, [id]);

  async function getUser() {
    const res: any = await Api.getUser(id);
    if (res.status == 200) {
      setUser(res?.data?.data);
      setIsLoading(false);
    }
  }
  const handleItemUpdate = async () => {
    await getUserItems();
  };

  const handleItemDelete = async (itemId: string) => {
    setItems(prevItems => prevItems.filter(item => item._id !== itemId));
  };
  async function getAllCollections() {
    const res: any = await Api.getAllCollection(id);
    if (res.status == 200) {
      const fetchedData = res?.data?.data;
      setCollections(res?.data?.data);

      setIsLoading(false);
    }
  }
  async function getUserItems() {
    const data = {
      id: id,
      //has to be updated
      token: id,
    };
    const res: any = await Api.getUserItem(data);

    if (res.status == 200) {
      let mostRecentUpdatedItems = res?.data?.data;
      setItemCount(mostRecentUpdatedItems?.length || 0);
      mostRecentUpdatedItems = mostRecentUpdatedItems.map(item => {
        return {
          ...item,
          updatedAt: new Date(item.updatedAt),
        };
      });

      mostRecentUpdatedItems.sort((a, b) => {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();

        return dateB - dateA;
      });
      const mostRecentFeaturedItems = mostRecentUpdatedItems.filter(
        item => item.pinned,
      );
      const mostRecentUpdatedNotFeaturedItems = mostRecentUpdatedItems.filter(
        item => !item.pinned,
      );
      const topMostRecent = mostRecentUpdatedNotFeaturedItems.slice(0, 24);

      setItems(topMostRecent);
      setFeaturedItems(mostRecentFeaturedItems);
    }
  }
  async function fetchFavoriteItems() {
    try {
      const res: any = await Api.getFavorites(id);
      if (res.status === 200) {
        setFavoriteItems(res.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch favorite items", error);
    }
  }
  const handlePinToggle = async (itemId: string, isPinned: boolean) => {
    try {
      await Api.updateItem({ itemId: itemId, pinned: isPinned });

      await getUserItems();
    } catch (error) {
      console.error("Failed to update pinned status", error);
    }
  };
  const visibilitySettings = user?.visibilitySettings || {};
  const isOwner = isUser?._id === id;
  if (isLoading) return null;
  if (visibilitySettings.profileVisible === false && isUser?._id !== id) {
    return (
      <div>
        <Header />
        <main className="flex items-center justify-center h-screen">
          <p>This profile is hidden from the public.</p>
        </main>
        <Footer />
      </div>
    );
  }
  return (
    <>
      <Header />
      <main>
        {!isLoading ? (
          <div className="py-8 md:py-12 bg-primary2">
            <div className="mx-auto max-w-[1560px] px-6 w-full">
              <div className="flex flex-wrap gap-4 md:gap-6 ">
                {isUser?._id === id && (
                  <div className="relative w-full sm:w-auto md:w-auto flex-shrink-0">
                    <button
                      onClick={() => setIsShow(prev => !prev)}
                      className="text-sm w-full sm:max-h-[30px] sm:mb-[-0.5rem]  md:max-h-[50px] md:mb-[-0.5rem] rounded-lg justify-center  bg-primary text-white flex items-center gap-2 py-3 px-5"
                    >
                      <CiSettings
                        size={20}
                        fill="#fff"
                      />
                      Account
                    </button>
                    {isShow && (
                      <div className="absolute mt-2 bg-white border border-gray-200 rounded-xl shadow-lg z-50 w-48 md:w-38 sm:right-auto md:left-auto ">
                        <div className="flex items-center gap-2 py-3 px-5 hover:bg-gray-100">
                          <FaMessage
                            size={16}
                            fill="#4979d1"
                          />
                          <Link
                            to={`/${id}/messages`}
                            className="text-sm text-primary hover:underline"
                          >
                            My Messages
                          </Link>
                        </div>
                        {links?.map((itm, idx) => (
                          <div
                            key={idx}
                            className="flex items-center gap-2 py-3 px-5 hover:bg-gray-100"
                          >
                            <itm.icon
                              size={16}
                              fill="#4979d1"
                            />
                            <Link
                              to={itm.link}
                              className="text-sm text-primary hover:underline"
                            >
                              {itm.title}
                            </Link>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                <div
                  className={`${isUser?._id !== id ? "w-full" : "flex-grow"}`}
                >
                  <Detail
                    isCollector={false}
                    user={user}
                    itemCount={itemCount}
                    isSameAsUser={isUser?._id === id}
                    isNotSameAsUser={!!(isUser?._id && isUser?._id !== id)}
                    isMessagingAllowed={visibilitySettings.messagingAllowed}
                  />
                </div>
              </div>

              <br />
              <br />
              {isUser?._id === id &&
                visibilitySettings.favoritesVisible &&
                favoriteItems.length > 0 && (
                  <>
                    <div>
                      <div className="flex items-center gap-2">
                        <span className="text-2xl md:text-3xl font-bold">
                          Favorites
                        </span>
                        <p className="w-[2px] h-7 bg-black"></p>
                        <Link
                          to="/favorites"
                          className="md:text-xl hover:underline leading-0"
                        >
                          See all
                        </Link>
                      </div>
                      <div className="p-3 md:p-5 rounded-2xl bg-[#D8E8FF]">
                        <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                          {favoriteItems.map((item, idx) => (
                            <Item
                              key={item._id || idx}
                              isPinned={item.pinned}
                              user_data={item.uid}
                              isFeatured={item.isFeatured}
                              isCountry={item.isCountry}
                              isSeller={isUser?._id === id}
                              name={item.name}
                              uid={item.uid._id}
                              description={item.description}
                              country={item.country}
                              photos={item.photos}
                              currency={item.currency}
                              price={item.price}
                              year={item?.year}
                              hidden={item?.hidden}
                              id={item?._id}
                              category={item.categories}
                              createdAt={item.createdAt}
                              updatedAt={item.updatedAt}
                              onPinToggle={handlePinToggle}
                              onDelete={handleItemDelete}
                              onUpdate={handleItemUpdate}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                  </>
                )}
              {featuredItems.length > 0 &&
                (isOwner ||
                  (visibilitySettings.featuredVisible &&
                    visibilitySettings.itemsVisible)) && (
                  <div>
                    <p className="text-2xl md:text-3xl text-primary font-bold mb-4">
                      Featured
                    </p>
                    <div className="p-3 md:p-5 rounded-2xl bg-[#D8E8FF]">
                      <div className="grid gap-4 grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                        {featuredItems.map((item, idx) => (
                          <Item
                            key={item._id || idx}
                            isPinned={item.pinned}
                            isFeatured={item.isFeatured}
                            isCountry={item.isCountry}
                            isSellerPage={true}
                            user_data={item.uid}
                            isSeller={isUser?._id === id}
                            name={item.name}
                            uid={item.uid._id}
                            description={item.description}
                            country={item.country}
                            photos={item.photos}
                            currency={item.currency}
                            price={item.price}
                            year={item?.year}
                            hidden={item?.hidden}
                            id={item?._id}
                            category={item.categories}
                            onPinToggle={handlePinToggle}
                            createdAt={item?.createdAt}
                            updatedAt={item?.updatedAt}
                            onDelete={handleItemDelete}
                            onUpdate={handleItemUpdate}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              <br />
              <br />

              {collections.length > 0 &&
                (isOwner ||
                  (visibilitySettings.collectionVisible &&
                    visibilitySettings.itemsVisible)) && (
                  <div>
                    <div className="flex  items-center gap-2 mb-4">
                      <span className="text-2xl md:text-3xl font-bold text-primary">
                        Collections
                      </span>
                      <p className="w-[2px] h-7 bg-primary"></p>
                      <Link
                        to={`/collection/all/${id}`}
                        className="md:text-xl hover:underline leading-0 text-primary"
                      >
                        See all
                      </Link>
                    </div>
                    <div className="p-3 max-w-[1560px] mx-auto md:p-5 rounded-2xl  bg-white">
                      <div className="grid justify-items-center justify-center gap-y-4 grid-cols-3 md:grid-cols-3 lg:grid-cols-3">
                        {collections?.slice(0, 3)?.map(collection => (
                          <div
                            key={collection._id}
                            className="collection-container"
                          >
                            <Collection
                              collection={collection}
                              isSeller={isUser?._id === id}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              {!collections.length && isUser?._id === id && (
                <div>
                  <div className="flex items-center gap-2 mb-4">
                    <span className="text-2xl md:text-3xl font-bold text-primary">
                      Collections
                    </span>
                    <p className="w-[2px] h-7 bg-primary"></p>
                    <Link
                      to={`/collection/all/${id}`}
                      className="md:text-xl hover:underline leading-0 text-primary"
                    >
                      See all
                    </Link>
                  </div>
                </div>
              )}
              <br />
              <br />
              {items.length > 0 &&
                (isOwner || visibilitySettings.itemsVisible) && (
                  <div>
                    <div className="flex items-center gap-2 mb-4 justify-between">
                      <div className="flex items-center gap-2">
                        <span className="text-2xl md:text-3xl text-primary font-bold">
                          Items
                        </span>
                        <p className="w-[2px] h-7 bg-black"></p>
                        <Link
                          to={`/items/${id}`}
                          className="md:text-xl hover:underline text-primary leading-0"
                        >
                          See all
                        </Link>
                      </div>
                      {isUser?._id === id && (
                        <Link
                          to={`/item/single/new`}
                          className="cursor-pointer  sm:max-h-[35px] md:max-h-[60px]  sm:mb-[-0.5rem] sm:px-1 sm:py-1 rounded-lg border border-primary bg-primary text-[#ffffff] text-base text-center md:px-4 md:py-1"
                        >
                          Add New Item
                        </Link>
                      )}
                    </div>
                    <div className="p-3 md:p-5 rounded-2xl bg-[#D8E8FF]">
                      <div className="grid gap-4 grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                        {items.map((item, idx) => (
                          <Item
                            key={item._id || idx}
                            isPinned={item.pinned}
                            isFeatured={item.pinned}
                            isCountry={item.isCountry}
                            isSeller={isUser?._id === id}
                            isSellerPage={true}
                            user_data={item.uid}
                            name={item.name}
                            uid={item.uid._id}
                            description={item.description}
                            country={item.country}
                            photos={item.photos}
                            updatedAt={item.updatedAt}
                            currency={item.currency}
                            price={item.price}
                            year={item?.year}
                            hidden={item?.hidden}
                            id={item?._id}
                            category={item.categories}
                            createdAt={item.createdAt}
                            onPinToggle={handlePinToggle}
                            onDelete={handleItemDelete}
                            onUpdate={handleItemUpdate}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        ) : (
          ""
        )}
      </main>
      <Footer />
    </>
  );
};

export default Seller;
