import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Api from "../../api/api";
import { useAuth } from "../../context/auth";
import { CiSettings } from "react-icons/ci";
import { links } from "../seller";
import avatar from "../../assets/avatar.jpg";
import { IUser } from "../../types/auth";
import { FaCamera } from "react-icons/fa";
import { countries } from "../../utils/constant";
import PhoneInput from "react-phone-input-2";
import Button from "../../common/Button";
import toast from "react-hot-toast";

const EditProfile: React.FC = () => {
  const location = useLocation();
  const [user, setUser] = useState<IUser>({
    fname: "",
    lname: "",
    country: "",
    phone: "",
    email: "",
    about: "",
    description: "",
    deliveryOption: "",
    avatar: "",
    _id: "",
    isDeactivated: false,
    isVerified: false,
    contact: "",
  });
  const imgRef = useRef<HTMLInputElement>(null);
  const [photo, setPhoto] = useState<string>(user?.avatar || avatar);
  const [tempAvatar, setTempAvatar] = useState<string>("");
  const { user: isUser } = useAuth();
  const [isShow, setIsShow] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (isUser?._id) {
      getUser(isUser?._id);
    }
  }, [isUser?._id]);

  useEffect(() => {
    setPhoto(user?.avatar || avatar);
  }, [user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!user?.phone) {
      return toast.error("Phone number is required");
    }

    setIsDisable(true);
    try {
      // Handle Avatar Upload
      if (tempAvatar) {
        const avatarUploadResponse = await Api.updateAvatar(tempAvatar);
        if (avatarUploadResponse) {
          setUser(prev => ({ ...prev, avatar: avatarUploadResponse.url }));
        } else {
          throw new Error("Failed to upload avatar.");
        }
      }

      // Handle Profile Data Update
      const res = await Api.updateMyProfile(user);
      if (res.status === 200) {
        toast.success("Profile updated successfully!");
        history.push(`/seller/${isUser?._id}`);
        await getUser(isUser?._id || "");
      } else {
        toast.error(res.message || "Failed to update profile");
      }
    } catch (error: any) {
      toast.error(error.message || "Error updating profile");
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  async function getUser(id: string) {
    const res: any = await Api.getUser(id);
    if (res.status == 200) {
      setUser(res?.data?.data);
    }
  }
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { value, name } = e.target;
    setUser(prev => ({ ...prev, [name]: value }));
  };
  const handleAvatarSubmit = async () => {
    if (photo === user.avatar) {
      return;
    }
    setIsDisable(true);
    try {
      const res = await Api.updateAvatar(photo);
      if (res) {
        setUser(prev => ({ ...prev, avatar: res.url }));
        setPhoto(res.url);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsDisable(false);
    }
  };
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTempAvatar(reader.result as string);
        setPhoto(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    if (isUser?._id) {
      getUser(isUser?._id);
    }
  }, [isUser?._id]);

  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <div className="w-[192px] relative mb-6">
              <button
                onClick={() => setIsShow(prev => !prev)}
                className="text-sm w-full rounded-lg justify-center bg-primary text-white flex items-center gap-2 py-3 px-5"
              >
                <CiSettings
                  size={20}
                  fill="#fff"
                />
                Account
              </button>
              {isShow && (
                <div className="absolute z-40 left-0 right-0 top-11 rounded-xl bg-white mt-2 py-2">
                  {links?.map((itm, idx) => (
                    <div
                      key={idx}
                      className="flex items-center gap-2 py-3 px-5"
                    >
                      <itm.icon
                        size={16}
                        fill="var(--primary)"
                      />
                      <Link
                        to={itm.link}
                        className={`text-sm text-primary hover:underline ${itm.link === location.pathname ? "underline" : ""}`}
                      >
                        {itm.title}
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <form
              onSubmit={handleSubmit}
              className="bg-white rounded-2xl p-4 md:p-6"
            >
              <input
                id="avatar"
                type="file"
                accept="image/png, image/jpeg, image/webp"
                className="hidden"
                ref={imgRef}
                onChange={handleImageChange}
                disabled={isDisable}
              />

              <label
                htmlFor="avatar"
                className="w-fit cursor-pointer block relative mb-6"
              >
                <FaCamera
                  fill="var(--primary)"
                  className="absolute right-1 bottom-1"
                />
                <img
                  src={photo ?? avatar}
                  alt="avatar"
                  className="size-[40px] md:size-[150px] rounded-xl"
                />
              </label>
              <div className="flex items-center gap-6 mb-6 flex-wrap">
                <div className="md:flex-1 max-md:w-full">
                  <p className="font-semibold">Firstname</p>
                  <input
                    name="fname"
                    value={user?.fname}
                    onChange={handleInputChange}
                    className="w-full border-b border-[#000] py-2 text-[15px]"
                    required
                    disabled={isDisable}
                  />
                </div>
                <div className="md:flex-1 max-md:w-full">
                  <p className="font-semibold">Lastname</p>
                  <input
                    name="lname"
                    value={user?.lname}
                    onChange={handleInputChange}
                    className="w-full border-b border-[#000] py-2 text-[15px]"
                    required
                    disabled={isDisable}
                  />
                </div>
              </div>

              <div className="flex items-center gap-6 mb-6 flex-wrap">
                <div className="md:flex-1 max-md:w-full">
                  <p className="font-semibold">Country</p>
                  <select
                    name="country"
                    value={user?.country}
                    onChange={handleInputChange}
                    className="w-full border-b border-[#000] py-2 text-[15px]"
                    required
                    disabled={isDisable}
                  >
                    {countries?.map((itm, idx) => (
                      <option
                        key={idx}
                        value={itm.value}
                      >
                        {itm.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="md:flex-1 max-md:w-full">
                  <p className="font-semibold">Phone number</p>
                  <PhoneInput
                    country={undefined}
                    value={user?.phone || ""}
                    containerClass="bg-[#fff] border-b border-[#000] rounded-none text-sm w-full"
                    inputClass="!h-[40px] !w-full !bg-[#fff] !border-none !rounded-none !pl-10"
                    buttonClass="!bg-[#fff] !border-none !rounded-none"
                    // className={`bg-[#f9fafb] border border-[#ddd] rounded-md overflow-hidden text-sm w-full [&>.form-control]:!h-[38px]
                    // [&>.form-control]:!w-full [&>.form-control]:!bg-[#f9fafb] [&>.flag-dropdown]:!border-none [&>.flag-dropdown]:!bg-[#f9fafb]
                    // [&_.selected-]:hover:!bg-[#f9fafb] [&>.form-control]:!border-none [&>.form-control]:!rounded-md
                    // [&>.form-control]:!pl-10 [&>.flag-dropdown]:!rounded-md`}
                    onChange={(value: string) =>
                      setUser(prev => ({ ...prev, phone: value }))
                    }
                    disabled={isDisable}
                  />
                </div>
              </div>

              <div className="mb-6 w-full">
                <p className="font-semibold">Email</p>
                <input
                  type="email"
                  name="email"
                  value={user?.email || ""}
                  onChange={handleInputChange}
                  className="w-full border-b border-[#000] py-2 text-[15px]"
                  required
                  disabled={isDisable}
                />
              </div>

              <div className="mb-6 w-full">
                <p className="font-semibold">About</p>
                <input
                  name="about"
                  value={user?.about || ""}
                  onChange={handleInputChange}
                  className="w-full border-b border-[#000] py-2 text-[15px]"
                  required
                  disabled={isDisable}
                />
              </div>

              <div className="mb-6">
                <p className="font-semibold mb-1">Profile Description</p>
                <textarea
                  name="description"
                  value={user?.description || ""}
                  onChange={handleInputChange}
                  maxLength={500}
                  className="w-full border border-[#000] min-w-full max-w-full min-h-[150px] rounded-md p-2 text-[15px]"
                  required
                  disabled={isDisable}
                ></textarea>
                <p className="text-end text-xs mt-1">
                  {user?.description?.length}/500 characters
                </p>
              </div>

              <div className="mb-6 w-full">
                <p className="font-semibold">Delivery Option</p>
                <input
                  name="deliveryOption"
                  maxLength={300}
                  value={user?.deliveryOption || ""}
                  onChange={handleInputChange}
                  className="w-full border-b border-[#000] py-2 text-[15px]"
                  disabled={isDisable}
                />
              </div>
              <div className=" mb-6 w-full">
                <p className="font-semibold pt-3">Contact</p>
                <input
                  name="contact"
                  value={user?.contact || ""}
                  maxLength={300}
                  onChange={handleInputChange}
                  className="w-full border-b border-[#000] text-[15px]"
                  disabled={isDisable}
                />
              </div>
              <div className="flex justify-end mt-8">
                <Button
                  type="submit"
                  className="!w-fit !px-6"
                  disabled={isDisable}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default EditProfile;
