import React from "react";
import { Link, useLocation } from "react-router-dom";
import { IAuthLayoutProps } from "../types/auth";
import logo from "../assets/logo2.svg";
import circle1 from "../assets/auth/circle1.svg";
import circle2 from "../assets/auth/circle2.svg";
import circle3 from "../assets/auth/circle3.svg";

const Auth: React.FC<IAuthLayoutProps> = ({
  title,
  submitHandle,
  children,
  width = "max-w-[500px]",
}) => {
  const location = useLocation();

  return (
    <div className="flex min-h-screen relative">
      <img
        src={circle1}
        className="absolute left-0 bottom-0 size-[150px] max-md:hidden"
        alt="circle"
      />
      <img
        src={circle2}
        className="absolute top-0 right-0 size-[150px] max-md:hidden"
        alt="circle"
      />
      <div className="grid w-full place-items-center min-h-[100%] p-6">
        <div className={`w-full mx-auto ${width}`}>
          <Link
            to="/"
            className="grid place-items-center mb-6 w-full mx-auto max-w-[500px]"
          >
            <img
              className="w-full"
              src={logo}
              alt="Numisnest Logo"
            />
          </Link>

          <form
            onSubmit={submitHandle}
            className="w-full bg-white px-4 md:px-6 py-8 md:py-10 rounded-xl md:rounded-2xl"
          >
            {title && (
              <h1 className="mb-1 text-center text-2xl md:text-3xl font-medium">
                {title}
              </h1>
            )}
            {location.pathname === "/auth/login" && (
              <p className="text-sm text-center mb-8">
                Welcome Back! enter your details to sign in
              </p>
            )}
            {children}
          </form>

          <img
            src={circle3}
            className="w-full h-[100px] mt-7"
            alt="circle"
          />
        </div>
      </div>
    </div>
  );
};

export default Auth;
