import React, { useEffect, useState, useRef } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Button from "../common/Button";
import Item from "../components/Items/Item";
import avatar from "../assets/avatar.jpg";
import truck from "../assets/profile/truck.png";
import notes from "../assets/notes2.png";
import { Link, useHistory, useParams } from "react-router-dom";
import Api from "../api/api";
import { IProps } from "../components/Items/Item";
import { useAuth } from "../context/auth";
import { FaHeart, FaRegHeart } from "react-icons/fa6";
import toast from "react-hot-toast";
import Modal from "react-modal";
import AdminHeader from "../components/Admin/AdminHeader";

Modal.setAppElement("#root");

const SingleItem: React.FC = () => {
  const [item, setItem] = useState<IProps | null>(null);
  const [seller, setSeller] = useState<any>();
  const { user, isAdmin } = useAuth();
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState<any>();
  const [isFavorite, setIsFavorite] = useState<boolean>(
    user?.favorites?.includes(item?._id) ?? false,
  );
  const [lastSelectedPhoto, setLastSelectedPhoto] = useState<string>(
    "https://via.placeholder.com/150",
  );
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [similarItems, setSimilarItems] = useState<IProps[]>([]);
  const { id } = useParams<{ id: string }>();
  const [isZoomedIn, setIsZoomedIn] = useState(false);
  const zoomRef = useRef<HTMLImageElement>(null);
  const history = useHistory();
  const [selectedPhoto, setSelectedPhoto] = useState<string>(
    "https://via.placeholder.com/150",
  );

  useEffect(() => {
    getItemByID();
  }, [id]);
  useEffect(() => {
    if (item) {
      getSimilarItems();
      console.log(similarItems);
    }
  }, [item]);
  async function getSimilarItems() {
    try {
      const res = await Api.getSimilarItems(id);
      if (res.status === 200) {
        setSimilarItems(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching similar items:", error);
    }
  }
  useEffect(() => {
    getCategory(item?.category);
    getSeller(item?.uid);
    if (item?.photos && item.photos.length > 0) {
      setSelectedPhoto(item.photos[0].url);
      setLastSelectedPhoto(item.photos[0].url);
    }
    console.log(user);
  }, [item]);
  useEffect(() => {
    console.log(user?.favorites);
    setIsFavorite(user?.favorites?.includes(item?._id) ?? false);
  }, [user]);
  async function getItemByID() {
    const res: any = await Api.getItemByID(id);

    if (res.status === 200) {
      setItem(res.data.data);
    }
  }
  const handleFavoriteToggle = async () => {
    if (!item?._id) return;

    try {
      if (isFavorite) {
        await Api.removeFromFavorites({ id: item._id });
        toast.success(`${item?.name} removed from favorites`);
      } else {
        await Api.addToFavorites({ id: item._id });
        toast.success(`${item?.name} added to favorites`);
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error("Failed to toggle favorite status", error);
    }
  };

  const updateFavoriteState = (itemId: string, isFavorited: boolean) => {
    setSimilarItems(prevItems =>
      prevItems.map(itm =>
        itm._id === itemId ? { ...itm, isFavorited } : itm,
      ),
    );
    if (item?._id === itemId) {
      setItem({ ...item, isFavorited });
    }
  };
  async function getSeller(uid: string | undefined) {
    if (!uid) return null;
    try {
      // @ts-expect-error temp

      const res = await Api.getUser(uid._id);
      if (res.status === 200) {
        setSeller(res.data.data);
        console.log(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    }
    return null;
  }
  const handleImageMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!zoomRef.current || !isZoomedIn) return;

    const { left, top, width, height } =
      zoomRef.current.getBoundingClientRect();
    const xPercent = ((e.clientX - left) / width) * 100;
    const yPercent = ((e.clientY - top) / height) * 100;

    zoomRef.current.style.transformOrigin = `${xPercent}% ${yPercent}%`;
  };
  const handleMouseEnterSide = (photoUrl: string) => {
    setSelectedPhoto(photoUrl);
  };

  const handleMouseLeaveSide = () => {
    setSelectedPhoto(lastSelectedPhoto);
  };

  const handleImageClickSidePanel = (photoUrl: string) => {
    setLastSelectedPhoto(photoUrl);
    setSelectedPhoto(photoUrl);
  };
  const handleImageMouseLeave = () => {
    if (isZoomedIn) {
      setIsZoomedIn(false);
    }
  };

  const handleImageClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!zoomRef.current) return;

    const { offsetX, offsetY } = e.nativeEvent;
    const { width, height } = zoomRef.current;
    const xPercent = (offsetX / width) * 100;
    const yPercent = (offsetY / height) * 100;

    if (isZoomedIn) {
      zoomRef.current.style.transformOrigin = "center center";
      setIsZoomedIn(false);
    } else {
      zoomRef.current.style.transformOrigin = `${xPercent}% ${yPercent}%`;
      setIsZoomedIn(true);
    }
  };
  async function getCategory(categoryId: string | undefined) {
    if (!categoryId) return null;
    try {
      const res = await Api.getCategory(categoryId);
      if (res.status === 200) {
        setCategory(res.data.data);
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
    return null;
  }
  const openMessageModalOrLogin = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (!user?._id) {
      history.push("/login");
    } else {
      setIsMessageModalOpen(true);
    }
  };
  const handleSendMessageClick = () => {
    console.log("Send Message button clicked");
    setIsMessageModalOpen(true);
    console.log("isMessageModalOpen", isMessageModalOpen);
  };

  const handleSendMessage = async () => {
    if (!message.trim()) return;
    const params = { receiverId: id, text: message };
    console.log(params);
    setIsLoading(true);
    try {
      const res = await Api.sendMessage(params);
      if (res.status === 201) {
        setIsMessageModalOpen(false);
        toast.success("Message sent successfully");
      } else {
        console.error("Failed to send message:", res.message);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {isAdmin() ? <AdminHeader /> : <Header />}
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1240px] px-6 w-full">
            <div className="flex flex-wrap max-lg:gap-4">
              <div className="w-full lg:w-[55%] lg:h-[450px] lg:pr-8">
                <div className="flex flex-wrap-reverse w-full gap-4 h-full">
                  <div
                    className={`grid  gap-2 lg:overflow-y-auto max-lg:overflow-x-auto lg:h-full ${
                      (item?.photos?.length || 0) + (item?.videos ? 1 : 0) > 7
                        ? "grid-cols-1"
                        : "grid-cols-1"
                    }`}
                  >
                    {" "}
                    <div className="flex gap-2 scrollable lg:flex-col lg:h-full">
                      {item?.photos?.map((photo, idx) => (
                        <div
                          key={idx}
                          className="bg-white w-[3.5rem] h-[3.5rem] overflow-hidden rounded-md border border-primary cursor-pointer"
                          onMouseEnter={() => handleMouseEnterSide(photo.url)}
                          onMouseLeave={handleMouseLeaveSide}
                          onClick={() => handleImageClickSidePanel(photo.url)}
                        >
                          <img
                            src={photo.url}
                            alt=""
                            className="size-full object-contain"
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    className="bg-[#D8E8FF] p-2 rounded-md w-full lg:w-[calc(100%_-_150px)]  "
                    onMouseMove={handleImageMouseMove}
                    onClick={handleImageClick}
                    onMouseLeave={handleImageMouseLeave}
                  >
                    <div className="zoom-container">
                      <img
                        ref={zoomRef}
                        src={selectedPhoto || "https://via.placeholder.com/150"}
                        alt="Main"
                        className={`zoomed-image size-full ${isZoomedIn ? "zoomed-in" : ""}`}
                        style={{ cursor: isZoomedIn ? "zoom-out" : "zoom-in" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-[45%] lg:pl-4">
                <h2 className="text-xl md:text-2xl font-semibold mb-4">
                  {item?.name || "?"}
                </h2>
                <hr className="bg-black h-0.5" />
                <p className="text-end max-md:text-sm text-primary font-semibold">
                  {item?.years?.join(", ") || "Not available"}
                </p>
                <div className="p-1.5 rounded-lg bg-white text-primary font-semibold text-xl w-fit">
                  {item?.price
                    ? `${item.price} ${item.currency}`
                    : "No price available"}
                </div>
                <br />
                <div className="bg-white whitespace-pre-wrap rounded-lg mt-4 border border-[#00000099] p-2.5 max-md:text-sm break-words">
                  {item?.description || `No description available`}
                </div>
                <div className="mt-2 flex items-center gap-3 flex-wrap text-xs md:text-sm">
                  <p>
                    Countries:{" "}
                    {item?.countries?.map((country, idx) => (
                      <span key={idx}>
                        {country}
                        {idx < (item.countries?.length ?? 0) - 1 ? ", " : ""}
                      </span>
                    )) || "-"}
                  </p>

                  <p>
                    Categories:{" "}
                    {item?.categories?.map((categ, idx) => (
                      <span key={idx}>
                        {categ.name}
                        {idx < (item.categories?.length ?? 0) - 1 ? ", " : ""}
                      </span>
                    )) || "-"}
                  </p>
                  <p>Year: {item?.years?.join(", ") || "Not applicable"}</p>
                </div>
                {user && user._id !== item?.uid && (
                  <div className="mt-4">
                    <button
                      className="flex items-center gap-2 text-sm font-semibold text-primary hover:text-red-500 transition-colors"
                      onClick={handleFavoriteToggle}
                    >
                      {isFavorite ? (
                        <>
                          <FaHeart style={{ fill: "red" }} /> Remove from
                          favorites
                        </>
                      ) : (
                        <>
                          <FaRegHeart
                            // Sets the color of the outlined heart
                            style={{ border: "red" }}
                          />{" "}
                          Add to Favorites
                        </>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>

            <br />

            <div className="md:mt-4 flex max-md:gap-4 flex-wrap-reverse">
              <div className="w-full md:w-[55%] md:pr-8">
                <Link to={`/seller/${seller?._id}`}>
                  <p className="md:text-lg font-semibold mb-1">Seller</p>
                  <div className="bg-white p-3 md:p-4 rounded-2xl border border-black w-fit">
                    <div className="flex items-center gap-3">
                      <img
                        src={seller?.avatar || avatar}
                        alt="Seller"
                        className="size-14"
                      />
                      <div>
                        <p className="text-lg md:text-xl font-semibold mb-1 md:mb-2">
                          {seller?.fname} {seller?.lname}
                        </p>

                        <div className="flex items-center gap-2 flex-wrap">
                          <p className="max-md:text-sm md:max-w-[200px]">
                            {seller?.about}
                          </p>
                          {user &&
                            user._id !== seller?._id &&
                            seller?.visibilitySettings?.messagingVisible && (
                              <Button
                                className="!w-fit"
                                onClick={openMessageModalOrLogin}
                              >
                                Message Seller
                              </Button>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="w-full md:w-[45%] md:pl-4">
                <div className="bg-[#85858530] rounded-2xl p-4">
                  <div className="flex items-center gap-2 mb-2">
                    <img
                      src={truck}
                      className="size-6"
                      alt="Purchase & delivery"
                    />
                    <span className="font-semibold max-md:text-sm">
                      Purchase & delivery
                    </span>
                  </div>
                  <p className="max-md:text-sm">
                    {seller?.deliveryOption
                      ? seller?.deliveryOption
                      : "No delivery option available"}
                  </p>
                </div>
              </div>
            </div>

            <br />
            <br />
            {similarItems.length > 0 && (
              <div>
                <p className="text-2xl md:text-3xl font-bold mb-4">
                  Similar Items
                </p>
                <div className="p-3 md:p-5 rounded-2xl bg-[#D8E8FF]">
                  <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                    {similarItems.map((iter, idx) => (
                      <Item
                        key={iter._id}
                        isPinned={iter.isPinned}
                        isFeatured={iter.isFeatured}
                        isCountry={iter.isCountry}
                        isSeller={iter.isSeller}
                        name={iter.name}
                        user_data={iter.uid}
                        uid={iter.uid._id}
                        description={iter.description}
                        country={iter.country}
                        photos={iter.photos}
                        currency={iter.currency}
                        price={iter.price}
                        year={iter.year}
                        years={iter.years}
                        id={iter._id}
                        hidden={iter.hidden}
                        category={iter.categories}
                        createdAt={iter.createdAt}
                        updatedAt={iter.updatedAt}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      <Modal
        isOpen={isMessageModalOpen}
        onRequestClose={() => setIsMessageModalOpen(false)}
        contentLabel="Send Message"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto md:min-w-[40%] mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2>Send a Message to {user?.fname + " " + user?.lname}</h2>
        <textarea
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder="Type your message here..."
          className="w-full p-2 border border-gray-300 rounded"
          rows={5}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={() => setIsMessageModalOpen(false)}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleSendMessage}
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={isLoading}
          >
            {isLoading ? "Sending..." : "Send"}
          </button>
        </div>
      </Modal>
      <Footer />
    </>
  );
};

export default SingleItem;
