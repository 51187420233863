import React, { useRef, useState, useEffect } from "react";
import { RiImageAddLine, RiInformationLine } from "react-icons/ri";
import { IoIosClose } from "react-icons/io";
import { FaPencilAlt } from "react-icons/fa";
import imageCompression from "browser-image-compression";
import {
  DndContext,
  closestCenter,
  DragOverlay,
  closestCorners,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  rectSortingStrategy,
} from "@dnd-kit/sortable";

import { CSS } from "@dnd-kit/utilities";
import { fileToBase64 } from "../helpers/functions";
import Tooltip from "./Tooltip";
import ImageEditor from "./ImageEditor";
import toast from "react-hot-toast";
import { MdVideoSettings } from "react-icons/md";

interface ImageUploaderProps {
  images: string[];
  setImages: React.Dispatch<React.SetStateAction<string[]>>;
  video: string;
  setVideo: React.Dispatch<React.SetStateAction<string>>;
}

const SortableImage = React.memo(
  ({ id, index, handleRemoveImage, openImageEditor, totalImages }: any) => {
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const borderStyle =
      index === 0 ? "border-4 border-primary" : "border border-primary";

    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
      setActivatorNodeRef,
    } = useSortable({
      id,
      disabled: isButtonClicked,
    });

    const wrapperStyle: React.CSSProperties = {
      transform: CSS.Transform.toString(transform),
      willChange: "transform",
      transition,
      ...(index === 0
        ? {
            height: window.innerWidth < 760 ? "7rem" : "14rem",
            width: window.innerWidth < 760 ? "7rem" : "14rem",
          }
        : {
            width: window.innerWidth < 760 ? "7rem" : "14rem",
            height: window.innerWidth < 760 ? "7rem" : "14rem",
          }),
      opacity: isDragging ? 0 : 1,
      zIndex: isDragging ? 10000 : "auto",
      position: "relative",
    };

    const handleClick = React.useCallback(
      (e: React.MouseEvent, action: () => void) => {
        e.stopPropagation();
        setIsButtonClicked(true);
        action();
      },
      [],
    );

    return (
      <div
        ref={setNodeRef}
        style={wrapperStyle}
        {...attributes}
        className={`relative rounded-lg p-2 overflow-hidden group ${borderStyle}`}
      >
        <img
          src={id}
          alt={`item-${index}`}
          className="w-full h-full object-contain"
        />
        <p className="absolute top-0.5 left-0.5 text-xs bg-white px-1 rounded">
          {index ? index + 1 : "Cover"}
        </p>

        <div
          ref={setActivatorNodeRef}
          {...listeners}
          className="absolute inset-0"
        >
          <button
            type="button"
            onClick={e => handleClick(e, () => openImageEditor(id, index))}
            className="absolute top-1 right-10 text-white p-1 bg-blue-500 rounded hover:bg-blue-700 opacity-0 group-hover:opacity-100 transition-opacity"
          >
            <FaPencilAlt />
          </button>
          <button
            type="button"
            onClick={e => handleClick(e, () => handleRemoveImage(index))}
            className="absolute top-1 right-2 text-white p-1 bg-red-500 rounded hover:bg-red-700 opacity-0 group-hover:opacity-100 transition-opacity"
          >
            <IoIosClose />
          </button>
        </div>
      </div>
    );
  },
);
SortableImage.displayName = "SortableImage";
const ImageUploader: React.FC<ImageUploaderProps> = ({
  images,
  setImages,
  video,
  setVideo,
}) => {
  const imgRef = useRef<HTMLInputElement>(null);
  const [isImageEditorOpen, setIsImageEditorOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null,
  );
  const [activeImage, setActiveImage] = useState<string | null>(null);
  const cloudinaryRef = useRef("");
  const widgetRef = useRef<any>(null);

  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  const openImageEditor = (imgSrc: string, index: number) => {
    setSelectedImage(imgSrc);
    setSelectedImageIndex(index);
    setIsImageEditorOpen(true);
  };

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleSaveEditedImage = (editedImgBase64: string) => {
    if (selectedImageIndex !== null) {
      setImages(prevImages => {
        const updatedImages = [...prevImages];
        updatedImages[selectedImageIndex] = editedImgBase64;
        return updatedImages;
      });
      toast.success("Image edited successfully");
    }
    setIsImageEditorOpen(false);
  };
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (window.cloudinary) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      cloudinaryRef.current = window.cloudinary;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      widgetRef.current = cloudinaryRef.current.createUploadWidget(
        {
          cloudName: process.env.REACT_APP_CLOUDNIARY_CLOUD_NAME,
          uploadPreset: process.env.REACT_APP_CLOUDNIARY_UPLOAD_PRESET,
          resourceType: "video",
          sources: ["local", "url", "camera"],
          maxFiles: 1,
          maxVideoFileSize: 50 * 1024 * 1024,
          clientAllowedFormats: ["mp4", "mov", "webm", "ogv"],
        },
        (error, result) => {
          if (error) {
            toast.error("Video upload failed. Please try again.");
          } else if (result && result.event === "success") {
            const cloudinaryUrl = result.info.secure_url;
            setVideo(cloudinaryUrl); // Set the Cloudinary video URL correctly
            toast.success("Video uploaded successfully!");
          }
        },
      );
    }
  }, [setVideo]);
  const handleImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      if (images.length + files.length > 13) {
        toast.error("Only 13 images are allowed");
        return;
      }

      const compressedFiles = await Promise.all(
        Array.from(files).map(async file => {
          const compressedFile = await imageCompression(file, {
            maxSizeMB: 2, // Maximum file size in MB
            maxWidthOrHeight: 1980, // Resize to a maximum width or height of 1200px
          });
          return fileToBase64(compressedFile);
        }),
      );
      const base64Strings = await Promise.all(compressedFiles);

      const uniqueImages = base64Strings.filter(
        newImage => !images.includes(newImage),
      );

      if (uniqueImages.length !== base64Strings.length) {
        toast.error("Duplicate images detected, only unique images were added");
      }

      setImages(prev => [...prev, ...uniqueImages]);
    }
  };
  const handleRemoveVideo = () => {
    setVideo("");
    toast.success("Video removed successfully!");
  };
  const handleRemoveImage = (index: number) => {
    setImages(prev => prev.filter((_, idx) => idx !== index));
  };

  const handleDragStart = (event: any) => {
    const { active } = event;
    setActiveImage(active.id);
  };
  const handleVideoUpload = (error, result) => {
    if (error) {
      console.error("Upload error:", error);
      toast.error("Video upload failed. Please try again.");
      return;
    }

    if (result && result.event === "success") {
      console.log("Upload result:", result.info);
      setVideo(result.info.secure_url);
      toast.success("Video uploaded successfully!");
    }
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    // Check if 'over' is not null
    if (!over) {
      setActiveImage(null);
      return;
    }

    if (active.id !== over.id) {
      const oldIndex = images.indexOf(active.id);
      const newIndex = images.indexOf(over.id);

      const updatedImages = arrayMove(images, oldIndex, newIndex);
      setImages(updatedImages);
    }

    setActiveImage(null);
  };
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    }),
  );

  const renderOverlayContent = () => {
    if (!activeImage) return null;

    return (
      <div
        style={{
          width: "10rem",
          height: "10rem",
          border: "2px solid blue",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <img
          src={activeImage}
          alt="Dragging"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </div>
    );
  };

  const gridStyles = {
    display: "grid",
    gridTemplateColumns: images.length === 0 ? "1fr 1fr" : "repeat(6, 1fr)",
    gap: "8px",
    ...(images.length === 0 && {
      gridTemplateColumns: "repeat(2, 1fr)",
      gap: "16px",
    }),
  };

  return (
    <>
      <DndContext
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext
          items={images}
          strategy={rectSortingStrategy}
        >
          <div
            className={`grid gap-4 ${
              images.length === 0
                ? "grid-cols-[70%_30%] w-full"
                : "grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5"
            }`}
            style={{ touchAction: "none" }}
          >
            {images.map((img, index) => (
              <SortableImage
                key={img}
                id={img}
                index={index}
                handleRemoveImage={handleRemoveImage}
                openImageEditor={openImageEditor}
                totalImages={images.length}
              />
            ))}

            {images.length < 13 && (
              <label
                htmlFor="images"
                className={`rounded-2xl border-[2px] border-dashed border-black grid place-items-center cursor-pointer ${
                  images.length === 0
                    ? "h-[200px] w-[full]"
                    : " h-[14rem] w-[14rem]"
                }`}
              >
                <div className="grid place-items-center">
                  <div className="size-12 bg-[#9CC6E240] grid place-items-center rounded-full">
                    <RiImageAddLine size={30} />
                  </div>
                  <p className="text-xs text-center font-medium mt-2">
                    Add photos or drag and drop
                  </p>
                </div>
              </label>
            )}

            {!video && (
              <label
                className={`rounded-2xl   border-[2px] border-dashed border-black grid place-items-center cursor-pointer ${
                  images.length === 0 && !video
                    ? "h-[200px] w-[full]"
                    : "sm:h-[7rem] sm:w-[7rem] md:h-[14rem] md:w-[14rem] lg:h-[14rem] lg:w-[14rem] "
                }`}
                onClick={() => widgetRef.current?.open()} // Trigger video upload
              >
                <div className="grid place-items-center">
                  <div className="size-12 bg-[#9CC6E240] grid place-items-center rounded-full">
                    <MdVideoSettings size={30} />
                  </div>
                  <p className="text-xs font-medium text-center mt-2">
                    Upload videos
                  </p>
                </div>
                <div
                  className="absolute top-2 right-2"
                  onMouseEnter={() => setIsTooltipVisible(true)}
                  onMouseLeave={() => setIsTooltipVisible(false)}
                >
                  <RiInformationLine
                    size={20}
                    className="text-gray-500 hover:text-gray-700 cursor-pointer"
                  />
                  {isTooltipVisible && (
                    <div
                      className="absolute right-0 bottom-full mb-2 bg-white text-black text-xs p-2 rounded shadow-lg"
                      style={{
                        width: "200px",
                        whiteSpace: "normal",
                        textAlign: "left",
                      }}
                    >
                      Up to 60 seconds and no bigger than 50 MB
                    </div>
                  )}
                </div>
              </label>
            )}
            {video && (
              <div className="relative rounded-2xl border-[2px] border-black">
                <video
                  className="w-full h-[11.5rem] object-cover rounded-lg"
                  controls
                  src={video}
                />
                <button
                  type="button"
                  onClick={handleRemoveVideo}
                  className="absolute top-2 right-2 text-white p-1 bg-red-500 rounded hover:bg-red-700"
                >
                  <IoIosClose size={20} />
                </button>
              </div>
            )}
          </div>
        </SortableContext>

        <DragOverlay>{renderOverlayContent()}</DragOverlay>
      </DndContext>

      <input
        ref={imgRef}
        id="images"
        type="file"
        onChange={handleImages}
        className="hidden"
        accept="image/png, image/jpeg, image/webp"
        multiple
      />

      {selectedImage && (
        <ImageEditor
          isOpen={isImageEditorOpen}
          imageSrc={selectedImage}
          onClose={() => setIsImageEditorOpen(false)}
          onSave={handleSaveEditedImage}
        />
      )}
    </>
  );
};

export default ImageUploader;
