import React from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Detail from "../components/Seller/Detail";
import Item from "../components/Items/Item";
import Collection from "../components/Collection";

const Collector: React.FC = () => {
  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <div className="w-full max-w-[1100px] mx-auto">
              <Detail
                isCollector={true}
                user={""}
              />
            </div>

            <br />
            <br />

            <div>
              <div className="flex items-center gap-2 mb-4">
                <span className="text-2xl md:text-3xl font-bold text-primary">
                  Collections
                </span>
                <p className="w-[2px] h-7 bg-primary"></p>
                <Link
                  to="#"
                  className="md:text-xl hover:underline leading-0 text-primary"
                >
                  See all
                </Link>
              </div>
              <div className="p-3 md:p-5 rounded-2xl bg-white">
                <div className="grid justify-items-center gap-y-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3"></div>
              </div>
            </div>
            <br />
            <br />

            <div>
              <div className="flex items-center gap-2 mb-4">
                <span className="text-2xl md:text-3xl font-bold">Items</span>
                <p className="w-[2px] h-7 bg-black"></p>
                <Link
                  to="/items"
                  className="md:text-xl hover:underline leading-0"
                >
                  See all
                </Link>
              </div>
              <div className="p-3 md:p-5 rounded-2xl bg-[#D8E8FF]">
                <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                  {Array(6)
                    .fill(6)
                    .map((_, idx) => (
                      <Item key={idx} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Collector;
