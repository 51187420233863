import "react-phone-input-2/lib/style.css";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import toast from "react-hot-toast";
import Auth from "../../layout/Auth";
import Input from "../../common/Input";
import { ISignupData } from "../../types/auth";
import Button from "../../common/Button";
import Select from "../../common/Select";
import { countries } from "../../utils/constant";
import Textarea from "../../common/Textarea";
import Api from "../../api/api";

const initialData = {
  fname: "",
  lname: "",
  email: "",
  country: "",
  phone: "",
  about: "",
  password: "",
  confirmPassword: "",
};

const Signup: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState<ISignupData>({ ...initialData });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { value, name } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (data.password !== data.confirmPassword) {
      return toast.error("Password doesn't match");
    }

    if (!data.phone) {
      return toast.error("Phone number is required");
    }

    setIsDisable(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { confirmPassword, ..._data } = data;
      const res = await Api.signup({
        ..._data,
        isNew: false,
        type: type || "seller",
      });
      if (res.status === 200) {
        history.replace("/auth/login");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    return () => {
      setData({ ...initialData });
      setIsDisable(false);
    };
  }, []);

  return (
    <Auth
      title="Create an Account"
      submitHandle={handleSubmit}
    >
      <div className="flex items-center md:gap-4 max-md:flex-wrap mt-4">
        <Input
          className="w-full md:flex-1"
          title="First Name*"
          name="fname"
          value={data?.fname}
          onChange={handleInputChange}
          placeholder="Enter First Name"
          required
        />
        <Input
          className="w-full md:flex-1"
          title="Last Name*"
          name="lname"
          value={data?.lname}
          onChange={handleInputChange}
          placeholder="Enter Last Name"
          required
        />
      </div>
      <Input
        type="email"
        title="Email*"
        name="email"
        value={data?.email}
        onChange={handleInputChange}
        placeholder="Enter Email"
        required
      />
      <Select
        title="Country*"
        name="country"
        value={data?.country}
        onChange={handleInputChange}
        required
        options={countries}
      />
      <div className="relative mt-4">
        <label className="capitalize block mb-1 text-secondary text-[15px]">
          Phone Number*
        </label>
        <PhoneInput
          country={data?.country?.toLowerCase()}
          containerClass="bg-[#fff] border border-[#A6A9ACB2] rounded-md text-sm w-full"
          inputClass="!h-[47px] !w-full !bg-[#fff] !border-none !rounded-md !pl-10"
          buttonClass="!bg-[#fff] !border-none !rounded-md"
          // className={`bg-[#f9fafb] border border-[#ddd] rounded-md overflow-hidden text-sm w-full [&>.form-control]:!h-[38px]
          // [&>.form-control]:!w-full [&>.form-control]:!bg-[#f9fafb] [&>.flag-dropdown]:!border-none [&>.flag-dropdown]:!bg-[#f9fafb]
          // [&_.selected-flag]:hover:!bg-[#f9fafb] [&>.form-control]:!border-none [&>.form-control]:!rounded-md
          // [&>.form-control]:!pl-10 [&>.flag-dropdown]:!rounded-md`}
          onChange={(value: string) =>
            setData(prev => ({ ...prev, phone: value }))
          }
          value={data.phone}
        />
      </div>

      <Textarea
        title="About"
        name="about"
        value={data?.about}
        onChange={handleInputChange}
        placeholder="Tell us about you and what you are collecting"
      />

      <Input
        type="password"
        title="Password*"
        name="password"
        value={data?.password}
        onChange={handleInputChange}
        placeholder="Enter Password"
        required
      />
      <Input
        type="password"
        title="Confirm Password*"
        name="confirmPassword"
        value={data?.confirmPassword}
        onChange={handleInputChange}
        placeholder="Confirm Password"
        required
      />

      <Button
        className="mt-7 mb-2"
        type="submit"
        disabled={isDisable}
      >
        Signup
      </Button>

      <div className="text-sm flex gap-1 items-center justify-center text-secondary">
        Already a member?
        <Link
          to="/auth/login"
          className="text-primary font-medium"
        >
          Log in
        </Link>
      </div>
    </Auth>
  );
};

export default Signup;
