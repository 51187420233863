import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { IconType } from "react-icons";
import { FaCoins, FaLocationDot } from "react-icons/fa6";
import ReactPaginate from "react-paginate";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Item from "../components/Items/Item";
import Filters from "../components/Items/Filters";
import { ISelectOption } from "../types/common";
import Dots from "../assets/dots.svg";
import avatar from "../assets/avatar.jpg";
import { useAuth } from "../context/auth";
import Api from "../api/api";
import { IUser } from "../types/auth";
import { countries } from "../utils/constant";
import { FaSpinner } from "react-icons/fa";
const UserItems = () => {
  const location = useLocation();
  const q = new URLSearchParams(location.search);
  const hasSeller = q.get("seller");
  const searchQuery = q.get("q") || "";
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [userData, setUserData] = useState<IUser | undefined>();
  const [items, setItems] = useState<any>([]);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);

  const [itemOffset, setItemOffset] = useState(0);
  const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const [showPage, setShowPage] = useState(false);
  const perPage = 48;
  const pageCount = Math.ceil(filteredItems.length / perPage);
  const [exchangeRates, setExchangeRates] = useState<any>({});
  const [selectedCategories, setSelectedCategories] = useState<
    ISelectOption[] | any
  >([]);
  const [selectedLocations, setSelectedLocations] = useState<
    ISelectOption[] | any
  >([]);
  const [selectedCountries, setSelectedCountries] = useState<
    ISelectOption[] | any
  >([]);

  const [currency, setCurrency] = useState("USD");
  const { id } = useParams<any>();
  const [search, setSearch] = useState("");
  const [years, setYears] = useState<{
    min: string | number;
    max: string | number;
  }>({ min: "", max: "" });

  const [prices, setPrices] = useState<{
    min: string | number;
    max: string | number;
  }>({ min: "", max: "" });

  const isSeller = useMemo(
    () => user?._id === hasSeller,
    [location.search, user],
  );

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * perPage) % filteredItems.length;
    setItemOffset(newOffset);
  };
  const getUser = useCallback(async (userId: string) => {
    try {
      const res = await Api.getUser(userId);
      if (res.status === 200) {
        setUserData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handlePinToggle = async (itemId: string, isPinned: boolean) => {
    try {
      await Api.updateItem({ itemId: itemId, pinned: !isPinned });

      await getUserItems();
    } catch (error) {
      console.error("Failed to update pinned status", error);
    }
  };
  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      setShowPage(true);
    }, 500);

    setDebounceTimeout(timeout);

    return () => clearTimeout(timeout);
  }, [
    searchQuery,
    items,
    selectedCategories,
    selectedLocations,
    currency,
    filteredItems,
  ]);
  useEffect(() => {
    if (hasSeller) {
      getUser(hasSeller);
    }
  }, [location.search]);
  useEffect(() => {
    getUserItems();
  }, [id]);
  const getExchangeRates = async () => {
    try {
      const response = await fetch(
        "https://api.exchangerate-api.com/v4/latest/USD",
      );
      const data = await response.json();
      setExchangeRates(data.rates);
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
    }
  };
  async function getUserItems() {
    const res: any = await Api.getUserItem({ id: id });
    console.log(res.data.data);
    if (res.status == 200) {
      const sortedItems = res.data.data.sort((a: any, b: any) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });

      setItems(sortedItems);
    }
  }

  const convertCurrency = (
    amount: string | number,
    fromCurrency: string,
    toCurrency: string,
  ): number => {
    if (
      !exchangeRates ||
      !exchangeRates[fromCurrency] ||
      !exchangeRates[toCurrency]
    ) {
      return typeof amount === "number" ? amount : parseFloat(amount);
    }

    const convertedAmount =
      ((typeof amount === "number" ? amount : parseFloat(amount)) /
        exchangeRates[fromCurrency]) *
      exchangeRates[toCurrency];

    return parseFloat(convertedAmount.toFixed(2));
  };
  useEffect(() => {
    getExchangeRates();
  }, []);
  useEffect(() => {
    setLoading(true);
    const convertAndFilterItems = () => {
      let updatedItems = items.map(item => ({
        ...item,
        convertedPrice: convertCurrency(item.price, item.currency, currency),
        selectedCurrency: currency,
      }));

      // Apply existing filters after currency conversion
      if (selectedLocations.length > 0) {
        updatedItems = updatedItems.filter(item =>
          selectedLocations.includes(item.uid?.country),
        );
      }

      setFilteredItems(updatedItems);
    };

    convertAndFilterItems();

    setLoading(false);
  }, [currency, items, selectedLocations]);

  useEffect(() => {
    let updatedItems = items;

    if (selectedLocations) {
      updatedItems = items.filter(
        item => item.uid?.country === selectedLocations,
      );
    }

    setFilteredItems(updatedItems);
  }, [selectedLocations, items]);

  useEffect(() => {
    let updatedItems = items;

    if (selectedLocations.length > 0) {
      updatedItems = items.filter(item =>
        selectedLocations.includes(item.uid?.country),
      );
    }

    setFilteredItems(updatedItems);
  }, [selectedLocations, items]);

  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <>
              <div className="flex items-center gap-2 mb-4 text-primary">
                <Select
                  title="View price in"
                  value={currency}
                  setValue={setCurrency}
                  Icon={FaCoins}
                  options={[
                    { value: "USD", label: "USD" },
                    { value: "EUR", label: "EUR" },
                    { value: "ILS", label: "ILS" },
                  ]}
                />
              </div>
            </>

            <p className="text-xl md:text-2xl font-bold mb-3 text-center">
              {!hasSeller ? "All Items" : "User's Items"}
            </p>

            <Filters
              selectedCurrency={currency}
              setFilteredItems={setFilteredItems}
              items={items}
              filteredItems={filteredItems}
              search={search}
              setSearch={setSearch}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              selectedCountries={selectedCountries}
              setSelectedCountries={setSelectedCountries}
              years={years}
              setYears={setYears}
              prices={prices}
              setPrices={setPrices}
              selectedLocations={selectedLocations}
              exchangeRates={exchangeRates}
              convertedPrices={filteredItems.map(i => ({
                id: i._id,
                convertedPrice: convertCurrency(i.price, i.currency, currency),
                currency: i.selectedCurrency || currency,
              }))}
            />
            {loading || !showPage ? (
              <div className="flex justify-center items-center py-10">
                <FaSpinner className="animate-spin text-3xl text-primary" />
              </div>
            ) : (
              <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                {filteredItems
                  .slice(itemOffset, itemOffset + perPage)
                  .map((item, idx) => (
                    <Item
                      key={item._id || idx}
                      isPinned={item.pinned}
                      isFeatured={item.pinned}
                      isCountry={item.isCountry}
                      isSeller={user?._id === item?.uid?._id}
                      name={item.name}
                      user_data={item.uid}
                      uid={item.uid._id}
                      description={item.description}
                      country={item.countries}
                      photos={item.photos}
                      category={item.categories}
                      currency={item.selectedCurrency}
                      price={convertCurrency(
                        item.price,
                        item.currency,
                        currency,
                      )}
                      year={item?.year}
                      hidden={item?.hidden}
                      id={item?._id}
                      createdAt={item?.createdAt}
                      updatedAt={item?.createdAt}
                      onPinToggle={handlePinToggle}
                      convertedPrice={convertCurrency(
                        item.price,
                        item.currency,
                        currency,
                      )}
                    />
                  ))}
              </div>
            )}
          </div>

          <br />
          <div>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="flex flex-wrap justify-center mt-4"
              pageClassName="mx-2"
              pageLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              previousClassName="mx-2"
              previousLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              nextClassName="mx-2"
              nextLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              breakClassName="mx-2"
              breakLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              activeClassName="bg-slate-200 rounded-full "
              activeLinkClassName="font-semibold bg-slate-200"
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

const Select = ({
  Icon,
  title,
  options,
  value,
  setValue,
}: {
  Icon: IconType;
  title: string;
  options: ISelectOption[];
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <>
      <Icon
        size={22}
        fill="var(--primary)"
      />

      <span className="font-medium text-sm text-primary">{title}</span>
      <select
        value={value}
        onChange={e => setValue(e.target.value)}
        className="rounded-md outline-none border border-primary px-3 py-2 text-sm"
      >
        {options.map((itm, idx) => (
          <option
            key={idx}
            value={itm.value}
          >
            {itm.label}
          </option>
        ))}
      </select>
    </>
  );
};
export default UserItems;
