import React, { useState } from "react";
import ReactSelect from "react-select"; // Import react-select
import ReactQuill from "react-quill"; // Import rich text editor
import "react-quill/dist/quill.snow.css"; // Rich text editor styling
import Modal from "react-modal"; // Modal for edit and delete
import { countries } from "../../utils/constant";

Modal.setAppElement("#root"); // Set app element for accessibility

const BlogPostForm = ({
  existingPost,
  onSave,
  onDelete,
  isDeleteMode,
  isOpen,
  onCancel,
}) => {
  const [title, setTitle] = useState(existingPost?.title || "");
  const [text, setText] = useState(existingPost?.text || "");
  const [selectedCountries, setSelectedCountries] = useState(
    existingPost?.countries
      ? existingPost.countries.map(countryCode => {
          const matchedCountry = countries.find(c => c.value === countryCode);
          return matchedCountry || { value: countryCode, label: countryCode };
        })
      : [],
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleSave = async () => {
    const blogPostData = {
      title,
      text,
      countries: selectedCountries.map(c => c.value), // Store the value instead of the object
    };

    if (existingPost) {
      await onSave(existingPost._id, blogPostData);
    } else {
      await onSave(null, blogPostData);
    }
  };

  const handleCountryChange = selectedOptions => {
    setSelectedCountries(selectedOptions || []);
  };

  // Handle delete modal toggle
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  return (
    <Modal
      isOpen={isOpen} // Passed down from the parent to control visibility
      onRequestClose={onCancel} // Close modal when clicking outside or pressing ESC
      contentLabel={isDeleteMode ? "Delete Blog Post" : "Edit Blog Post"}
      className="bg-white p-6 rounded-lg shadow-xl max-w-lg mx-auto mt-24"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      {isDeleteMode ? (
        <div>
          <h2 className="text-lg font-bold mb-4">Delete Blog Post</h2>
          <p>Are you sure you want to delete this blog post?</p>
          <div className="flex justify-end gap-4 mt-6">
            <button
              className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              onClick={onCancel} // Close modal on cancel
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
              onClick={() => onDelete(existingPost._id)}
            >
              Delete
            </button>
          </div>
        </div>
      ) : (
        <div>
          <h2 className="text-2xl font-semibold mb-6 text-gray-700">
            {existingPost ? "Edit Blog Post" : "Create Blog Post"}
          </h2>

          <input
            type="text"
            value={title}
            onChange={e => setTitle(e.target.value)}
            placeholder="Title"
            className="mb-4 p-3 border border-gray-300 rounded-md w-full focus:ring-2 focus:ring-blue-500"
          />

          <ReactQuill
            value={text}
            onChange={setText}
            className="mb-4 overflow-y-auto max-h-[40vh] "
            theme="snow"
            placeholder="Write your blog content here..."
          />

          <label className="block mb-2 text-lg font-medium text-gray-700">
            Select Countries:
          </label>

          <ReactSelect
            value={selectedCountries}
            onChange={handleCountryChange}
            options={countries}
            closeMenuOnSelect={false}
            isMulti // Allow multiple countries
            className="mb-6"
            classNamePrefix="select"
            placeholder="Select countries"
          />

          <div className="flex justify-end gap-4">
            <button
              className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              onClick={onCancel} // Close modal on cancel
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default BlogPostForm;
