import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { IconType } from "react-icons";
import { FaCoins, FaLocationDot } from "react-icons/fa6";
import ReactPaginate from "react-paginate";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Item from "../components/Items/Item";
import Filters from "../components/Items/Filters";
import { ISelectOption } from "../types/common";
import Dots from "../assets/dots.svg";
import avatar from "../assets/avatar.jpg";
import { useAuth } from "../context/auth";
import Api from "../api/api";
import { IUser } from "../types/auth";

const Search = () => {
  const location = useLocation();
  const q = new URLSearchParams(location.search);
  const hasSeller = q.get("seller");
  const query = q.get("q") || "";

  const { user } = useAuth();
  const [userData, setUserData] = useState<IUser | undefined>();
  //const { users, setUser } = useState<any>([]);
  const [items, setItems] = useState<any>([]);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [itemOffset, setItemOffset] = useState(0);
  const perPage = 48;

  const pageCount = Math.ceil(filteredItems.length / perPage);
  const [exchangeRates, setExchangeRates] = useState<any>({});
  const [selectedCategories, setSelectedCategories] = useState<
    ISelectOption[] | any
  >([]);
  const [selectedCountries, setSelectedCountries] = useState<
    ISelectOption[] | any
  >([]);
  const [_location, setLocation] = useState("");
  const [currency, setCurrency] = useState("USD");

  const [search, setSearch] = useState(query);
  const [years, setYears] = useState<{
    min: string | number;
    max: string | number;
  }>({ min: "", max: "" });

  const [prices, setPrices] = useState<{
    min: string | number;
    max: string | number;
  }>({ min: "", max: "" });

  const isSeller = useMemo(
    () => user?._id === hasSeller,
    [location.search, user],
  );

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * perPage) % filteredItems.length;
    setItemOffset(newOffset);
  };
  const getUser = useCallback(async (id: string) => {
    try {
      const res = await Api.getUser(id);
      if (res.status === 200) {
        setUserData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  async function getItems() {
    const data = {
      id: user?._id,
      token: user?._id,
    };
    const res: any = await Api.getAllItem(data);

    if (res.status === 200) {
      const fetchedItems: any[] = res?.data?.data;
      fetchedItems.sort((a, b) => {
        const dateA = new Date(a.UpdatedAt).getTime();
        const dateB = new Date(b.UpdatedAt).getTime();

        return dateB - dateA;
      });
      setItems(fetchedItems);
    }
  }
  useEffect(() => {
    if (hasSeller) {
      getUser(hasSeller);
    }
  }, [location.search]);
  useEffect(() => {
    getItems();
  }, [user]);
  useEffect(() => {
    const filtered = items.filter(item =>
      item.name.toLowerCase().includes(search.toLowerCase()),
    );
    setFilteredItems(filtered);
  }, [search, items]);
  const getExchangeRates = async () => {
    try {
      const response = await fetch(
        "https://api.exchangerate-api.com/v4/latest/USD",
      );
      const data = await response.json();
      setExchangeRates(data.rates);
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
    }
  };
  const convertCurrency = (
    amount: string | number,
    fromCurrency: string,
    toCurrency: string,
  ): number => {
    if (
      !exchangeRates ||
      !exchangeRates[fromCurrency] ||
      !exchangeRates[toCurrency]
    ) {
      return typeof amount === "number" ? amount : parseFloat(amount);
    }

    const convertedAmount =
      ((typeof amount === "number" ? amount : parseFloat(amount)) /
        exchangeRates[fromCurrency]) *
      exchangeRates[toCurrency];

    return parseInt(convertedAmount.toFixed(2));
  };
  useEffect(() => {
    getExchangeRates();
  }, []);
  useEffect(() => {
    const updatedItems = items.map(item => ({
      ...item,
      convertedPrice: convertCurrency(item.price, item.currency, currency),
      selectedCurrency: currency,
    }));
    setFilteredItems(updatedItems);
  }, [currency, items]);

  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            {!hasSeller ? (
              <>
                <div className="relative flex items-center gap-2 mb-4 text-primary">
                  <Select
                    title="View results from"
                    value={_location}
                    setValue={setLocation}
                    Icon={FaLocationDot}
                    options={[{ value: "everywhere", label: "Everywhere" }]}
                  />
                  <img
                    src={Dots}
                    alt="dots"
                    className="max-md:hidden absolute right-0 top-12 -z-10"
                  />
                </div>
                <div className="flex items-center gap-2 mb-4 text-primary">
                  <Select
                    title="View price in"
                    value={currency}
                    setValue={setCurrency}
                    Icon={FaCoins}
                    options={[
                      { value: "USD", label: "USD" },
                      { value: "EUR", label: "EUR" },
                      { value: "ILS", label: "ILS" },
                    ]}
                  />
                </div>
              </>
            ) : (
              <div className="flex justify-end mb-2">
                <div className="bg-white border border-black rounded-xl p-3 md:p-4 flex items-center gap-3 md:gap-4 w-fit">
                  <img
                    src={avatar}
                    className="size-[50px] md:size-[80px]"
                    alt="Seller"
                  />
                  <div>
                    <p className="font-bold md:text-xl mb-1">
                      {/* {userData?.fname} {userData?.lname} */}
                      Full Name
                    </p>
                    <span className="text-sm">World banknotes</span>
                  </div>
                </div>
              </div>
            )}

            <p className="text-xl md:text-2xl font-bold mb-3 text-center">
              {!hasSeller ? "All Items" : "User's Items"}
            </p>

            <Filters
              setFilteredItems={setFilteredItems}
              items={items}
              search={search}
              exchangeRates={exchangeRates}
              setSearch={setSearch}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              selectedCountries={selectedCountries}
              setSelectedCountries={setSelectedCountries}
              years={years}
              setYears={setYears}
              prices={prices}
              setPrices={setPrices}
            />

            <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
              {filteredItems
                .slice(itemOffset, itemOffset + perPage)
                .map((item, idx) => (
                  <Item
                    key={item._id || idx}
                    isPinned={item.pinned}
                    isFeatured={item.isFeatured}
                    isCountry={item.isCountry}
                    isSeller={user?._id === item?.uid}
                    name={item.name}
                    user_data={item.uid}
                    uid={item.uid._id}
                    category={item.categories}
                    description={item.description}
                    country={item.country}
                    photos={item.photos}
                    currency={item.selectedCurrency}
                    price={convertCurrency(item.price, item.currency, currency)}
                    year={item?.year}
                    hidden={item?.hidden}
                    id={item?._id}
                    createdAt={item?.createdAt}
                    updatedAt={item?.updatedAt}
                  />
                ))}
            </div>
          </div>

          <br />
          <div>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="flex flex-wrap justify-center mt-4"
              pageClassName="mx-2"
              pageLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              previousClassName="mx-2"
              previousLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              nextClassName="mx-2"
              nextLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              breakClassName="mx-2"
              breakLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              activeClassName="bg-slate-200 rounded-full "
              activeLinkClassName="font-semibold bg-slate-200"
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

const Select = ({
  Icon,
  title,
  options,
  value,
  setValue,
}: {
  Icon: IconType;
  title: string;
  options: ISelectOption[];
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <>
      <Icon
        size={22}
        fill="var(--primary)"
      />

      <span className="font-medium text-sm text-primary">{title}</span>
      <select
        value={value}
        onChange={e => setValue(e.target.value)}
        className="rounded-md outline-none border border-primary px-3 py-2 text-sm"
      >
        {options.map((itm, idx) => (
          <option
            key={idx}
            value={itm.value}
          >
            {itm.label}
          </option>
        ))}
      </select>
    </>
  );
};
export default Search;
