import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { BsFillPinAngleFill, BsFillBookmarkDashFill } from "react-icons/bs";
import { FaUserCog } from "react-icons/fa";
import Button from "../../common/Button";
import Api from "../../api/api";
import Modal from "react-modal";
import { FaSpinner } from "react-icons/fa6";
import toast from "react-hot-toast";
import { useAuth } from "../../context/auth";
export interface IProps {
  isPinned?: boolean;
  isFeatured?: boolean;
  isCountry?: boolean;
  isSeller?: boolean;
  name?: string;
  uid?: any;
  years?: any[];
  id?: string;
  _id?: string;
  countries?: any[];
  categories?: any[];
  isCategory?: boolean;
  description?: string;
  country?: any;
  photos?: { url: string }[];
  videos?: any;
  currency?: string;
  price?: number;
  isFavorited?: boolean;
  year?: number;
  hidden?: boolean;
  category?: any;
  createdAt?: string;
  updatedAt?: string;
  user?: any;
  user_data?: any;
  isAddItem?: boolean;
  isSelectable?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  onPinToggle?: (id: string, isPinned: boolean) => void;
  onDelete?: (itemId: string) => void;
  onUpdate?: () => void;
  convertedPrice?: any;
  isSellerPage?: boolean;
  onSelectItem?: any;
  isSelected?: boolean;
  isHiddenTemp?: boolean;
}

const Item: React.FC<IProps> = ({
  isPinned,
  isFeatured = false,
  isCountry = false,
  isSeller = false,
  isSellerPage = false,
  name,
  uid,
  isAddItem,
  description,
  country,
  years,
  photos: images,
  currency = "USD",
  price,
  year,
  id,
  category,
  user_data,
  hidden = false,
  createdAt,
  isCategory = false,
  updatedAt,
  countries,
  categories,
  onClick,
  isSelected,
  onSelectItem,
  isSelectable = false,
  onPinToggle,
  onDelete,
  onUpdate,
  convertedPrice,
  isHiddenTemp = false,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [pinned, setPinned] = useState(isPinned);
  const [user, setUser] = useState<any>(user_data);
  const history = useHistory();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { isAdmin } = useAuth();
  const toggleDropdown = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownVisible(prev => !prev);
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectItem(id!, e.target.checked);
  };
  const handleUpdateItem = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      if (isAdmin()) {
        const res = await Api.updateItemAdmin({ itemId: id });
        if (res.status === 200) {
          toast.success("Item updated successfully.");
        }
      } else {
        const res = await Api.updateItem({ itemId: id });
        if (res.status === 200) {
          toast.success("Item updated successfully.");
        }
      }
    } catch (error) {
      toast.error("Failed to update item.");
    }
  };
  const handleOptionClick = async (e: React.MouseEvent, action: string) => {
    e.preventDefault();
    e.stopPropagation();

    setDropdownVisible(false);

    if (action === "Update Item") {
      if (isAdmin()) {
        history.push(`/admin/item/manage/${id}`);
      } else {
        history.push(`/item/manage/${id}`);
      }
    } else if (action === "Delete Item") {
      setIsDeleteModalOpen(true);
    } else if (action === "Hide Item") {
      await handleHideItem();
    } else if (action === "Favorite") {
      await handleFavoriteToggle();
    } else if (action === "Feature Item") {
      await handleTogglePinned();
    } else if (action === "Delete" && onDelete) {
      onDelete(id!);
    }
  };
  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    // Check if focus is within the dropdown and prevent closing if so
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.relatedTarget as Node)
    ) {
      setDropdownVisible(false); // Close the dropdown
    }
  };
  const handleFavoriteToggle = async () => {
    try {
      const isFavorite = user?.favorites?.includes(id);
      if (isFavorite) {
        await Api.removeFromFavorites({ itemId: id });
      } else {
        await Api.addToFavorites({ itemId: id });
      }
      toast.success(isFavorite ? "Item unfavorited" : "Item favorited");
    } catch (error) {
      console.error("Failed to toggle favorite status", error);
      toast.error("Failed to toggle favorite status");
    }
  };
  const handleDeleteItem = async () => {
    setIsDeleting(true);
    try {
      if (isAdmin()) {
        const res: any = await Api.deleteItemAdmin(id);
        if (res.status === 200) {
          if (onDelete) onDelete(id!);
          if (onUpdate) onUpdate();
          setIsDeleteModalOpen(false);
          toast.success(res.message);
        }
      } else {
        const res: any = await Api.deleteItem(id);
        if (res.status === 200) {
          if (onDelete) onDelete(id!);
          if (onUpdate) onUpdate();
          setIsDeleteModalOpen(false);
          toast.success(res.message);
        }
      }
    } catch (error) {
      console.error("Failed to delete item", error);
    } finally {
      setIsDeleting(false);
    }
  };
  const handlePrice = (priceTag: number) => {
    if (priceTag.toString().length > 4) {
      return priceTag.toFixed(0);
    }
    return priceTag.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleHideItem = async () => {
    const updatedHiddenStatus = !hidden;
    const data = { itemId: id, hidden: updatedHiddenStatus };

    try {
      if (isAdmin()) {
        const res = await Api.updateItemAdmin(data);
        if (res.status === 200) {
          setPinned(updatedHiddenStatus);
          if (onUpdate) onUpdate();
        }
      } else {
        const res = await Api.updateItem(data);
        if (res.status === 200) {
          setPinned(updatedHiddenStatus);
          if (onUpdate) onUpdate();
        }
      }
    } catch (error) {
      console.error("Failed to hide item", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownVisible &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);

  const handleTogglePinned = async () => {
    try {
      const updatedPinnedStatus = !pinned;

      setPinned(updatedPinnedStatus);
      if (onPinToggle && id) {
        onPinToggle(id, updatedPinnedStatus);
      }
      toast.success(updatedPinnedStatus ? "Item featured" : "Item unfeatured");
    } catch (error) {
      console.error("Failed to update pinned status", error);
      toast.error("Failed to update pinned status");
    }
  };
  async function getUser() {
    console.log(uid);
    const res: any = await Api.getUser(uid);
    console.log("item.tsx");
    if (res.status == 200) {
      setUser(res?.data?.data);
    }
  }
  useEffect(() => {
    setPinned(isPinned);
  }, [isPinned]);

  if (hidden && !isSeller) {
    return null;
  }
  const truncateTitle = (title: string, maxLength: number) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength - 3) + "...\n\n\n";
    }
    return title;
  };

  const maxTitleLength = window.innerWidth <= 768 ? 40 : 50;
  const truncatedName = name
    ? truncateTitle(name, maxTitleLength)
    : "Unknown Item";

  const formatDate = dateString => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year_temp = date.getFullYear().toString().slice(-2);

    return `${day}.${month}`;
  };
  const padTitle = (title: string, maxLines: number) => {
    const lineHeight = 20;
    const wordsPerLine = 50;
    const totalCharacters = maxLines * wordsPerLine;
    if (title.length < totalCharacters) {
      return title + "\u00A0".repeat(totalCharacters - title.length);
    }
    return title;
  };
  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent the blur event when clicking inside the dropdown
  };
  const paddedTitle = padTitle(truncatedName, 3);
  if (hidden && !isSeller) {
    return null;
  }
  const content = (
    <>
      {/*isSeller && !isPinned && !isCategory && (
        <div
          onClick={handleTogglePinned}
          className="absolute -top-1 -right-1 size-8 rounded-full grid place-items-center hover:transition-all hover:bg-[#f2f2f2]"
        >
          <BsFillPinAngleFill
            size={18}
            fill="var(--primary)"
            className="rotate-[270deg] cursor-pointer"
          />
        </div>
      )*/}

      {isSeller && isPinned && !isCategory && (
        <div
          onClick={handleTogglePinned}
          className="absolute -top-1 -right-1 size-8 rounded-full grid place-items-center hover:transition-all hover:bg-[#f2f2f2]"
        >
          <BsFillBookmarkDashFill
            size={18}
            fill="var(--primary)"
            className="cursor-pointer"
          />
        </div>
      )}
      {/*  {isFeatured && (
    <Button
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className="absolute bottom-1 right-1 !text-[10px] !px-2 !py-[1px] !w-fit"
    >
      {pinned ? "Unpin" : "Pin"} Item
    </Button>
  )}*/}
      {isSeller && !isCategory && (
        <div className="absolute top-1 left-1">
          <Button
            onClick={toggleDropdown}
            className="flex items-center gap-1 !text-[10px] !px-2 !py-[1px]"
          >
            <FaUserCog
              fill="white"
              size={12}
            />{" "}
            Manage
          </Button>
          {dropdownVisible && (
            <div
              ref={dropdownRef}
              tabIndex={-1} // Make the dropdown focusable
              onBlur={handleBlur}
              className="absolute top-8 left-0 bg-white border border-gray-300 rounded-md shadow-lg z-10"
            >
              <button
                onClick={handleUpdateItem}
                className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
              >
                Update
              </button>
              <button
                onClick={e => handleOptionClick(e, "Update Item")}
                className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
              >
                Edit
              </button>
              <button
                onClick={e => handleOptionClick(e, "Hide Item")}
                className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
              >
                {hidden ? "Unhide" : "Hide"}
              </button>
              {!isAdmin() && (
                <button
                  onClick={e => handleOptionClick(e, "Feature Item")}
                  className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
                >
                  {pinned ? "Unfeature" : "Feature"}
                </button>
              )}
              <button
                onClick={e => handleOptionClick(e, "Delete Item")}
                className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
              >
                Delete
              </button>
            </div>
          )}
        </div>
      )}
      {isAdmin() && !isHiddenTemp && (
        <div className="absolute top-1 right-1 z-50">
          <input
            type="checkbox"
            checked={isSelected}
            onChange={handleCheckboxChange}
            onClick={e => e.stopPropagation()}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
        </div>
      )}
      {isSeller && isCategory && !isAddItem && (
        <div className="absolute top-1 left-1">
          <Button
            onClick={toggleDropdown}
            className="flex items-center gap-1 !text-[10px] !px-2 !py-[1px]"
          >
            <FaUserCog
              fill="white"
              size={12}
            />{" "}
            Manage
          </Button>
          {dropdownVisible && (
            <div className="absolute top-8 left-0 bg-white border border-gray-300 rounded-md shadow-lg z-10">
              <button
                onClick={e => handleOptionClick(e, "Delete")}
                className="block px-4 py-2 text-sm text-black hover:bg-gray-100 w-full text-left"
              >
                Delete
              </button>
            </div>
          )}
        </div>
      )}
      {!isSellerPage && !isSeller && user && (
        <div className="flex items-center gap-2 mb-1">
          {user.country && (
            <img
              className="w-[20px] sm:max-w-[15px] md:min-w-[20px]  rounded"
              src={`https://flagcdn.com/32x24/${user?.country?.toLowerCase()}.png`}
              alt={`${user.country} flag`}
            />
          )}
          <p
            style={{
              fontSize: window.innerWidth < 760 ? "0.7rem" : "1.05rem",
            }}
            className="overflow-hidden line-clamp-1 ml-[-0.2rem] mr-[-1rem] text-sm md:text-xl lg:text-sm font-semibold"
          >
            {user.fname} {user.lname}
          </p>
        </div>
      )}

      {isAdmin() && (
        <div
          className="flex items-center gap-2 mb-1"
          style={{ paddingTop: "2rem" }}
        >
          {user.country && (
            <img
              className="w-[20px] sm:max-w-[15px] md:min-w-[20px] rounded"
              src={`https://flagcdn.com/32x24/${user?.country?.toLowerCase()}.png`}
              alt={`${user.country} flag`}
            />
          )}
          <p
            style={{
              fontSize: window.innerWidth < 760 ? "0.65rem" : "1rem",
            }}
            className="overflow-hidden line-clamp-1 text-sm md:text-xl lg:text-sm font-semibold"
          >
            {user.fname} {user.lname}
          </p>
        </div>
      )}
      {isCountry && country && !isSellerPage && (
        <div className="flex items-center gap-2 mb-3">
          <img
            className="w-[20px] rounded"
            src={`https://flagcdn.com/32x24/${country?.toLowerCase()}.png`}
            alt={`${country} flag`}
          />
          <p className="overflow-hidden line-clamp-1 text-sm font-semibold">
            {country}
          </p>
        </div>
      )}
      {isCountry && country && !isSellerPage && (
        <div className="flex items-center gap-2 mb-3">
          <img
            className="w-[20px] rounded"
            src={`https://flagcdn.com/16x12/${country?.toLowerCase()}.png`}
            alt={`${country} flag`}
          />
          <p className="overflow-hidden line-clamp-1 text-sm font-semibold">
            {country}
          </p>
        </div>
      )}
      <div
        className=" aspect-square grid place-items-center mb-3 overflow-hidden"
        style={{ margin: "-0.0rem -0.75rem 0 -0.75rem" }}
      >
        <img
          className="max-w-full max-h-full min-w-full min-h-full object-contain"
          src={
            images && images.length > 0
              ? images[0]?.url
              : "https://via.placeholder.com/150"
          }
          alt={name || "Item image"}
        />
      </div>
      <div className="flex flex-col mt-1">
        <p
          className="text-xs lg:text-xs mb-2 line-clamp-3 font-semibold"
          style={{
            minHeight: window.innerWidth < 760 ? "2rem" : "2.6rem",
            fontSize: window.innerWidth < 760 ? "0.62rem" : "1.05rem",
            maxHeight: window.innerWidth < 760 ? "2rem" : "2.8rem",
          }}
        >
          {truncatedName || "Unknown Item"}
        </p>
        <div className="mt-auto">
          <div
            className="flex flex-col flex-nowrap  sm:flex-row items-start md:items-center justify-between"
            style={{ marginBottom: "-0.75rem" }}
          >
            <span
              style={{
                fontSize: window.innerWidth < 760 ? "0.75rem" : "1.4rem",
              }}
              className="font-semibold text-xs  md:text-lg lg:text-lg whitespace-nowrap"
            >
              {price
                ? `${currency} ${handlePrice(Number(price))}`
                : "Price not available"}
            </span>
            <span
              style={{
                fontSize: window.innerWidth < 760 ? "0.6rem" : "0.95rem",
              }}
              className="text-xs sm:text-xs md:text-sm text-[#1a6bee] whitespace-nowrap"
            >
              {updatedAt ? formatDate(updatedAt) : "Unknown Date"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
  if (isSelectable && onClick) {
    return (
      <div
        className={`relative block bg-white rounded-xl p-3 h-full cursor-pointer hover:transition-all hover:shadow-[#00000033_0px_0px_10px_-2px] ${
          hidden && isSeller ? "opacity-50" : ""
        }`}
        onClick={onClick}
      >
        {content}
      </div>
    );
  }
  return (
    <>
      <div className={hidden && isSeller ? "opacity-50" : ""}>
        <Link
          to={`/item/${id}`}
          className="relative  block bg-white rounded-xl pt-0 pb-3 pr-3 pl-3 h-full hover:transition-all hover:shadow-[#00000033_0px_0px_10px_-2px]"
        >
          {content}
        </Link>
      </div>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Confirm Delete Item"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-semibold mb-4">Confirm Delete</h2>
        <p>
          Are you sure you want to delete this item? This action cannot be
          undone.
        </p>
        <div className="flex justify-end gap-4 mt-6">
          <Button
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-4 py-2 bg-gray-200"
            disabled={isDeleting}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteItem}
            className="px-4 py-2 bg-red-500 text-white"
            disabled={isDeleting}
          >
            {isDeleting ? (
              <FaSpinner className="animate-spin mr-2" />
            ) : (
              "Yes, Delete"
            )}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Item;
