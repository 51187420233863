/* eslint-disable react/prop-types */
import React from "react";

interface BlogImagesProps {
  value: {
    url?: any;
    alt?: any;
  };
}

const BlogImages: React.FC<BlogImagesProps> = ({ value }) => {
  return (
    <img
      src={value.url}
      alt={value.alt || "Image"}
      className="w-full h-auto object-contain"
    />
  );
};

export default BlogImages;
