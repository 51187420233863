import React, { useRef, useState, useCallback, useEffect } from "react";
import Modal from "react-modal";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  FaUndo,
  FaRedo,
  FaSun,
  FaSave,
  FaTimes,
  FaSyncAlt,
  FaCrop,
} from "react-icons/fa";
import Slider from "react-slider";
import Button from "../common/Button";

interface ImageEditorProps {
  isOpen: boolean;
  imageSrc: string;
  onClose: () => void;
  onSave: (editedImage: string) => void;
}

const ImageEditor: React.FC<ImageEditorProps> = ({
  isOpen,
  imageSrc,
  onClose,
  onSave,
}) => {
  const [crop, setCrop] = useState<Crop>({
    unit: "%",
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  });
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
  const [brightness, setBrightness] = useState(100);
  const [rotation, setRotation] = useState(0);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isCropActive, setIsCropActive] = useState(false);
  const onImageLoaded = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      const img = e.currentTarget;
      imgRef.current = img;
    },
    [],
  );

  const getCroppedImg = useCallback(() => {
    if (!imgRef.current || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const img = imgRef.current;

    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;
    const ctx = canvas.getContext("2d");

    if (!ctx) return;

    // If there is a crop, calculate the dimensions for the cropped image
    const cropX = completedCrop ? completedCrop.x * scaleX : 0;
    const cropY = completedCrop ? completedCrop.y * scaleY : 0;
    const cropWidth = completedCrop
      ? completedCrop.width * scaleX
      : img.naturalWidth;
    const cropHeight = completedCrop
      ? completedCrop.height * scaleY
      : img.naturalHeight;

    canvas.width = cropWidth;
    canvas.height = cropHeight;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Apply brightness filter
    ctx.filter = `brightness(${brightness}%)`;

    // Apply rotation transformation
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.translate(-canvas.width / 2, -canvas.height / 2);

    // Draw the image with transformations
    ctx.drawImage(
      img,
      cropX, // Crop X coordinate (0 if no crop)
      cropY, // Crop Y coordinate (0 if no crop)
      cropWidth, // Crop width (full image if no crop)
      cropHeight, // Crop height (full image if no crop)
      0, // Destination X
      0, // Destination Y
      cropWidth, // Destination width (same as cropWidth)
      cropHeight, // Destination height (same as cropHeight)
    );

    return canvas.toDataURL("image/jpeg");
  }, [completedCrop, brightness, rotation]);

  const handleSave = () => {
    const croppedImage = getCroppedImg();
    if (croppedImage) {
      onSave(croppedImage);
    }
  };

  const handleReset = () => {
    setCrop({
      unit: "%",
      width: 50,
      height: 50,
      x: 25,
      y: 25,
    });
    setCompletedCrop(null);
    setBrightness(100);
    setIsCropActive(false);
    setRotation(0);
  };

  useEffect(() => {
    if (isOpen) {
      handleReset();
    }
  }, [isOpen, imageSrc]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      className="bg-white p-6 rounded-xl  max-w-md mx-auto my-1 outline-none shadow-lg relative z-[10000]"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          zIndex: 10000,
        },
      }}
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Edit Image</h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700"
        >
          <FaTimes size={20} />
        </button>
      </div>
      <div className="relative flex  flex-col items-center">
        <div className="flex justify-center items-center min-h-[500px]">
          {isCropActive ? (
            <ReactCrop
              crop={crop}
              onChange={newCrop => setCrop(newCrop)}
              onComplete={c => setCompletedCrop(c)}
            >
              <img
                src={imageSrc}
                alt="Crop"
                ref={imgRef}
                onLoad={onImageLoaded}
                className="max-h-64 w-auto"
                style={{
                  filter: `brightness(${brightness}%)`,
                  transform: `rotate(${rotation}deg)`,

                  maxHeight: "26rem",
                }}
              />
            </ReactCrop>
          ) : (
            <img
              src={imageSrc}
              alt="Crop"
              ref={imgRef}
              onLoad={onImageLoaded}
              className="max-h-64  w-auto"
              style={{
                filter: `brightness(${brightness}%)`,
                transform: `rotate(${rotation}deg)`,
                maxHeight: "26rem",
              }}
            />
          )}
          <canvas
            ref={canvasRef}
            style={{ display: "none" }}
          />
        </div>
        <div className="flex gap-4 mt-6">
          <div className="flex flex-col items-center">
            <label className="text-sm font-semibold mb-2">Crop</label>
            <div
              onClick={() => setIsCropActive(!isCropActive)}
              className="cursor-pointer p-1 hover:text-green-500 transition-colors"
              title={isCropActive ? "Disable Crop" : "Enable Crop"}
            >
              <FaCrop
                size={20}
                style={{ color: isCropActive ? "green" : "black" }}
              />
            </div>
          </div>
          <div className="flex flex-col items-center">
            <label className="text-sm font-semibold mb-2">Rotation</label>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setRotation(rotation - 90)}
                className="p-2 rounded-full bg-gray-100 hover:bg-gray-200"
              >
                <FaUndo />
              </button>
              <button
                onClick={() => setRotation(rotation + 90)}
                className="p-2 rounded-full bg-gray-100 hover:bg-gray-200"
              >
                <FaRedo />
              </button>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <label className="text-sm font-semibold mb-2">Brightness</label>
            <div className="flex items-center space-x-2">
              <FaSun className="text-yellow-500" />
              <Slider
                value={brightness}
                onChange={value => setBrightness(value as number)}
                min={0}
                max={200}
                className="w-32 flex-grow relative"
                thumbClassName="bg-blue-500 h-4 w-4 rounded-full z-10 mt-[-0.3rem]"
                trackClassName="bg-gray-200 h-1 z-0"
              />
            </div>
          </div>

          <div className="flex flex-col items-center">
            <label className="text-sm font-semibold mb-2">Reset</label>
            <button
              onClick={handleReset}
              className="p-2 rounded-full bg-gray-100 hover:bg-gray-200"
            >
              <FaSyncAlt />
            </button>
          </div>
        </div>

        <div className="flex justify-end gap-4 mt-8">
          <Button
            onClick={onClose}
            className="bg-red-500 text-gray-700 hover:bg-gray-400"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            className="bg-blue-500 text-white hover:bg-blue-600"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ImageEditor;
