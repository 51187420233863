import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Auth from "../../layout/Auth";
import Input from "../../common/Input";
import { ILoginData } from "../../types/auth";
import Button from "../../common/Button";
import { ROLES } from "../../utils/constant";
import Api from "../../api/api";

const Login: React.FC = () => {
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState<ILoginData>({ email: "", password: "" });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsDisable(true);

    try {
      const res = await Api.login(data);
      if (res.status === 200) {
        if (ROLES.ADMIN === res.data.user.role) {
          window.location.replace("/admin/dashboard");
        } else {
          window.location.href = `/seller/${res.data.user._id}`;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    return () => {
      setData({ email: "", password: "" });
      setIsDisable(false);
    };
  }, []);

  return (
    <Auth
      title="Welcome Back"
      submitHandle={handleSubmit}
    >
      <Input
        type="email"
        title="email"
        name="email"
        value={data?.email}
        onChange={handleInputChange}
        placeholder="Enter Email"
        disabled={isDisable}
        required
      />
      <Input
        type="password"
        className="mt-6"
        title="password"
        name="password"
        value={data?.password}
        onChange={handleInputChange}
        placeholder="Enter Password"
        disabled={isDisable}
        required
      />

      <div className="flex items-center justify-between mt-3">
        <Link
          className="text-sm font-medium text-primary"
          to="/auth/forgot-password"
        >
          Forgot password?
        </Link>
      </div>

      <Button
        className="mt-7 mb-2"
        type="submit"
        disabled={isDisable}
      >
        Log in
      </Button>

      <div className="text-sm flex gap-1 items-center justify-center text-secondary">
        Don’t have an account?
        <Link
          to="/auth/signup"
          className="text-primary font-medium"
        >
          Sign Up
        </Link>
      </div>
    </Auth>
  );
};

export default Login;

