import React, { useState, useEffect } from "react";
import Api from "../api/api"; // Adjust the API path as per your structure
import { countries } from "../utils/constant"; // The constant countries list
import Footer from "../common/Footer"; // Footer component (adjust the import path as necessary)
import Header from "../common/Header";
import { useAuth } from "../context/auth";
import { Card, Text, Group, Divider, Select, rem } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';

const BlogPosts = () => {
  const [allBlogPosts, setAllBlogPosts] = useState<any[]>([]);
  const [filteredBlogPosts, setFilteredBlogPosts] = useState<any[]>([]);
  const [selectedCountry, setSelectedCountry] = useState("Everywhere");
  const { user } = useAuth();

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const allCountries: any[] = allBlogPosts.flatMap(post => post.countries);

  const uniqueCountries: any[] = Array.from(new Set(allCountries));

  const countriesFormatted = uniqueCountries.map(countryCode => {
    const matchedCountry = countries.find(c => c.value === countryCode);
    return {
      label: matchedCountry ? matchedCountry.label : countryCode,
      value: countryCode,
    };
  });

  console.log(countriesFormatted);

  // Fetch all blog posts
  const fetchBlogPosts = async () => {
    const res = await Api.getAllBlogPost();
    setAllBlogPosts(res.data.data);
    setFilteredBlogPosts(res.data.data);
  };

  // Handle filtering by country
  const handleCountryFilter = (value: any) => {
    setSelectedCountry(value);

    // If no value is selected (empty or "Everywhere"), show all blog posts
    if (!value || value === "Everywhere") {
      setFilteredBlogPosts(allBlogPosts);
    } else {
      setFilteredBlogPosts(allBlogPosts.filter(post => post.countries.includes(value)));
    }
  };



  return (
    <>
      <Header />
      <main className="p-6 mx-auto max-w-[1240px] bg-gray-100 min-h-screen">
        {/* Header with country filter */}
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center">
            <Select
              label="Showing from"
              rightSection={
                <IconChevronDown className="text-gray-500" style={{ width: rem(16), height: rem(16) }} />}
              data={countriesFormatted}
              value={selectedCountry}
              clearable
              onChange={(value: any) => handleCountryFilter(value)}
            />
          </div>
        </div>

        {/* Blog posts grid */}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {filteredBlogPosts.length > 0 ? (
            filteredBlogPosts.map((post, index) => (
              <Card
                key={post._id}
                shadow="sm"
                padding="lg"
                radius="md"
                withBorder
                className="flex flex-col h-[300px] transition-all duration-300 transform hover:-translate-y-1 hover:shadow-lg"
              >
                <Group>
                  <Text title={post.title} fw={600} truncate>
                    {post.title}
                  </Text>
                </Group>

                <Divider my={5} />

                <div className="relative flex-grow overflow-hidden">
                  <Text
                    size="sm"
                    className={`${post.isExpanded ? "max-h-none" : "max-h-[14rem] overflow-y-auto"
                      }`}
                    dangerouslySetInnerHTML={{ __html: post.text }}
                  />
                </div>

                {user?.role === 0 && (
                  <Text size="sm" color="dimmed" className="mt-auto">
                    <strong>Countries:</strong> {post.countries.join(", ")}
                  </Text>
                )}
              </Card>
            ))
          ) : (
            <Text size="sm" color="dimmed" className="col-span-full">
              No blog posts available.
            </Text>
          )}
        </div>

      </main>
      <Footer />
    </>
  );
};

export default BlogPosts;
