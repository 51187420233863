import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Item from "./Item";
import Api from "../../api/api";

interface ItemData {
  _id: string;
  isPinned: boolean;
  isFeatured: boolean;
  isCountry: boolean;
  isSeller: boolean;
  name: string;
  uid: {
    _id: string;
  };
  description: string;
  country: string;
  photos: { url: string }[];
  currency: string;
  price: number;
  year: number;
  hidden: boolean;
  categories: any[];
  createdAt: string;
  updatedAt: string;
}

const Items: React.FC<{ isCountry?: boolean; isSorted?: boolean }> = ({
  isSorted = false,
  isCountry = false,
}) => {
  const [items, setItems] = useState<ItemData[]>([]);

  useEffect(() => {
    getAllItems();
  }, []);

  async function getAllItems() {
    const res: any = await Api.getAllItem(null);
    if (res.status == 200) {
      const sortedItems = res.data.data.sort((a: ItemData, b: ItemData) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });

      const groupedByDate = groupByDate(sortedItems);
      const finalItems: ItemData[] = [];

      for (const date in groupedByDate) {
        const groupedItems = groupBySeller(groupedByDate[date]);
        finalItems.push(...alternateSellers(groupedItems));
      }
      if (isSorted) {
        setItems(finalItems.slice(0, 30));
        return;
      }
      setItems(finalItems);
    }
  }

  const groupByDate = (ite: ItemData[]): Record<string, ItemData[]> => {
    return ite.reduce((acc: Record<string, ItemData[]>, item: ItemData) => {
      const dateKey = new Date(item.updatedAt).toISOString().split("T")[0];
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(item);
      return acc;
    }, {});
  };

  const groupBySeller = (ite: ItemData[]): Record<string, ItemData[]> => {
    return ite.reduce((acc: Record<string, ItemData[]>, item: ItemData) => {
      const sellerId = item.uid._id;
      if (!acc[sellerId]) {
        acc[sellerId] = [];
      }
      acc[sellerId].push(item);
      return acc;
    }, {});
  };

  const alternateSellers = (
    groupedItems: Record<string, ItemData[]>,
  ): ItemData[] => {
    const sellerIds = Object.keys(groupedItems);
    const alternatedItems: ItemData[] = [];

    let index = 0;

    while (Object.values(groupedItems).some(group => group.length > 0)) {
      const sellerId = sellerIds[index % sellerIds.length];
      const sellerItems = groupedItems[sellerId];
      if (sellerItems.length > 0) {
        alternatedItems.push(sellerItems.shift()!);
      }
      index++;
    }

    return alternatedItems;
  };

  return (
    <div className="py-8 md:py-12 bg-primary2">
      <div className="mx-auto max-w-[1560px] sm:px-1 md:px-6 w-full">
        <div className="flex items-center gap-2 mb-6">
          <span className="text-2xl md:text-3xl font-bold whitespace-">
            {} Items
          </span>
          <p className="w-[2px] h-7 bg-black"></p>
          <Link
            to="/items"
            className="md:text-xl hover:underline leading-0"
          >
            See all
          </Link>
        </div>
        <div className="grid sm:gap-2 md:gap-4 grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
          {items.map(item => (
            <Item
              key={item._id}
              isPinned={item.isPinned}
              isFeatured={item.isFeatured}
              isCountry={item.isCountry}
              isSeller={item.isSeller}
              name={item.name}
              uid={item.uid._id}
              description={item.description}
              country={item.country}
              photos={item.photos}
              currency={item.currency}
              price={item.price}
              year={item.year}
              id={item._id}
              user_data={item.uid}
              hidden={item.hidden}
              category={item.categories}
              createdAt={item.createdAt}
              updatedAt={item.updatedAt}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Items;
