import React, { useState, useEffect } from "react";
import BlogPostForm from "../../components/Admin/BlogPostForm";
import Api from "../../api/api";
import { countries } from "../../utils/constant";
import AdminHeader from "../../components/Admin/AdminHeader";
import Footer from "../../common/Footer";
import { Card, Text, Group, Divider, Button, Select, rem } from '@mantine/core';
import { useAuth } from "../../context/auth";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { IconChevronDown, IconEdit, IconTrash } from "@tabler/icons-react";

const BlogPostList = () => {
  const [allBlogPosts, setAllBlogPosts] = useState<any[]>([]);
  const [filteredBlogPosts, setFilteredBlogPosts] = useState<any[]>([]);
  const [selectedCountry, setSelectedCountry] = useState("All");
  const [editingPost, setEditingPost] = useState(null);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = async () => {
    const res = await Api.getAllBlogPost();
    setAllBlogPosts(res?.data?.data);
    setFilteredBlogPosts(res?.data?.data);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setEditingPost(null);
  };

  const handleCountryFilter = (value: any) => {
    setSelectedCountry(value);

    // If no value is selected (empty or "Everywhere"), show all blog posts
    if (!value || value === "Everywhere") {
      setFilteredBlogPosts(allBlogPosts);
    } else {
      setFilteredBlogPosts(allBlogPosts.filter(post => post.countries.includes(value)));
    }
  };

  const handleSave = async (id, blogPostData) => {
    if (id) {
      await Api.updateBlogPost(id, blogPostData);
    } else {
      console.log(blogPostData);
      await Api.createBlogPost(blogPostData);
    }
    closeModal();
    fetchBlogPosts();
  };

  const handleDelete = async (id) => {
    await Api.deleteBlogPost(id);
    closeModal();
    fetchBlogPosts();
  };

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    // Reorder filteredBlogPosts
    const updatedFilteredPosts = [...filteredBlogPosts];
    const [movedPost] = updatedFilteredPosts.splice(dragIndex, 1);
    updatedFilteredPosts.splice(hoverIndex, 0, movedPost);
    setFilteredBlogPosts(updatedFilteredPosts);

    // Reorder allBlogPosts
    const updatedAllPosts = [...allBlogPosts];
    const [movedPostAll] = updatedAllPosts.splice(dragIndex, 1);
    updatedAllPosts.splice(hoverIndex, 0, movedPostAll);
    setAllBlogPosts(updatedAllPosts);

    // Log the entire updated allBlogPosts array
    console.log('Updated allBlogPosts:', updatedAllPosts);
  };


  const DraggableCard = ({ post, index }) => {
    const [, ref] = useDrag({
      type: "CARD",
      item: { index },
    });

    const [, drop] = useDrop({
      accept: "CARD",
      hover(item: { index: number }) {
        if (item.index !== index) {
          moveCard(item.index, index);
          item.index = index;
        }
      },
    });

    return (
      <div ref={(node) => ref(drop(node))}>
        <Card
          key={post._id}
          shadow="sm"
          padding="lg"
          radius="md"
          withBorder
          className="flex flex-col h-[300px] transition-all duration-300 transform hover:-translate-y-1 hover:shadow-lg cursor-move"
        >
          <Group grow preventGrowOverflow={false} wrap="nowrap" gap={3}>
            <Text w={350} title={post.title} fw={600} truncate>
              {post.title}
            </Text>
            <Button
              variant="outline"
              color="blue"
              styles={{
                root: {
                  padding: 10,
                  width: "auto"
                }
              }}
              onClick={() => {
                setEditingPost(post);
                setIsDeleteMode(false);
                openModal();
              }}
            >
              <IconEdit
                color="#5474b4"
                size="1.5rem"
              />
            </Button>
            <Button
              variant="outline"
              color="red"
              onClick={() => {
                setEditingPost(post);
                setIsDeleteMode(true);
                openModal();
              }}
              styles={{
                root: {
                  padding: 10,
                  width: "auto"
                }
              }}
            >
              <IconTrash
                color="red"
                size="1.5rem"
              />
            </Button>
          </Group>

          <Divider my={5} />

          <div className="relative flex-grow overflow-hidden">
            <Text
              size="sm"
              className={`${post.isExpanded ? "max-h-none" : "max-h-[12rem] overflow-y-auto"
                }`}
              dangerouslySetInnerHTML={{ __html: post.text }}
            />
          </div>

          {user?.role === 0 && (
            <Group gap={4}>
              <Text fw={600} fs="sm"> Countries:</Text> <Text size="sm" color="dimmed">{post.countries.join(", ")}</Text>
            </Group>
          )}
        </Card>
      </div>
    );
  };

  const allCountries: any[] = allBlogPosts.flatMap(post => post.countries);

  const uniqueCountries: any[] = Array.from(new Set(allCountries));

  const countriesFormatted = uniqueCountries.map(countryCode => {
    const matchedCountry = countries.find(c => c.value === countryCode);
    return {
      label: matchedCountry ? matchedCountry.label : countryCode,
      value: countryCode,
    };
  });

  return (
    <DndProvider backend={HTML5Backend}>
      <AdminHeader />
      <main>
        <div className="p-6 bg-gray-50 mx-auto max-w-[1240px] min-h-screen">
          {isModalOpen && (
            <BlogPostForm
              existingPost={editingPost}
              onSave={handleSave}
              onDelete={isDeleteMode ? handleDelete : null}
              isDeleteMode={isDeleteMode}
              onCancel={closeModal}
              isOpen={isModalOpen}
            />
          )}

          <div className="flex justify-between items-center mb-6">
            <h2 className="text-3xl font-bold text-gray-800">Blog Posts</h2>
            <Group >
              <Text >
                Filter by Country:
              </Text>
              <Select
                w={200}
                rightSection={
                  <IconChevronDown className="text-gray-500" style={{ width: rem(16), height: rem(16) }} />}
                data={countriesFormatted}
                value={selectedCountry}
                clearable
                onChange={(value: any) => handleCountryFilter(value)}
              />
              <Button
                color="green"
                 variant="filled"
                onClick={() => {
                  setEditingPost(null);
                  setIsDeleteMode(false);
                  openModal();
                }}
              >
                Create Blog Post
              </Button>
            </Group>
          </div>

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {filteredBlogPosts.length > 0 ? (
              filteredBlogPosts.map((post, index) => (
                <DraggableCard key={post._id} post={post} index={index} />
              ))
            ) : (
              <Text size="sm" color="dimmed" className="col-span-full">
                No blog posts available.
              </Text>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </DndProvider>
  );
};

export default BlogPostList;
