import React from "react";
import { Link } from "react-router-dom";
import Auth from "../../layout/Auth";
import collectorImg from "../../assets/auth/collector.svg";
import selletImg from "../../assets/auth/seller.svg";

const SelectSignup: React.FC = () => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <Auth
      title="I am a"
      width="max-w-[700px]"
      submitHandle={handleSubmit}
    >
      <div className="flex items-center gap-5 md:gap-8 md:flex-wrap mt-6 mb-4 lg:max-w-[80%] mx-auto">
        <Card
          type={"collector"}
          image={collectorImg}
        />
        <Card
          type={"seller"}
          image={selletImg}
        />
      </div>

      <div className="text-sm  flex gap-1 items-center justify-center text-secondary">
        Already a member?
        <Link
          to="/auth/login"
          className="text-primary font-medium"
        >
          Log in
        </Link>
      </div>
    </Auth>
  );
};

const Card = ({ type, image }: { type: string; image: string }) => {
  return (
    <Link
      to={`/auth/signup?type=${type}`}
      className="aspect-square rounded-xl md:rounded-2xl bg-bg-gray flex-1 p-5 md:p-7 grid place-content-center"
    >
      <div>
        <div className="bg-white rounded-md md:rounded-lg grid place-content-center p-3 md:p-5 aspect-square">
          <img
            src={image}
            alt={type}
            className="min-w-[40px] size-[40px] md:size-[70px]"
          />
        </div>
        <p className="text-center mt-2 capitalize text-accent">{type}</p>
      </div>
    </Link>
  );
};

export default SelectSignup;

