import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Button from "../common/Button";
import Api from "../api/api";

const CreateCollectionPage = () => {
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 70) {
      setTitle(value);
      setError("");
    } else {
      setError("Title cannot be longer than 70 characters.");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (title.trim() === "") {
      setError("Title is required.");
      return;
    }

    try {
      const res = await Api.addCollection({ name: title });
      if (res.status === 200) {
        history.push(`/collection/${res.data.message}`);
      }
    } catch (err) {
      // Changed 'error' to 'err' here
      console.error("Failed to create collection", err);
      setError("Failed to create collection. Please try again.");
    }
  };

  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <h1 className="text-center text-3xl font-bold mb-8">
              Adding a new collection
            </h1>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col items-center"
            >
              <label
                htmlFor="title"
                className="text-lg font-medium mb-2"
              >
                Title:
              </label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={handleTitleChange}
                className="w-full max-w-lg p-2 border border-gray-300 rounded-md mb-4"
                placeholder="Enter collection title"
              />
              {error && <p className="text-red-500 mb-4">{error}</p>}
              <Button
                type="submit"
                className="px-6 py-2"
              >
                Create
              </Button>
            </form>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default CreateCollectionPage;
