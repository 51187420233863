import React, { useState } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Api from "../api/api";
import toast from "react-hot-toast";
import Input from "../common/Input";
import Textarea from "../common/Textarea";
import Button from "../common/Button";

interface IForm {
  fname: string;
  lname: string;
  email: string;
  phone: string;
  message: string;
}

const initialFormState: IForm = {
  fname: "",
  lname: "",
  email: "",
  phone: "",
  message: "",
};

const Contact = () => {
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState<IForm>(initialFormState);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value, name } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    return;

    setIsDisable(true);

    try {
      const res = await Api.login(data);
      if (res.status === 200) {
        toast.success("Form submitted successfully");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  return (
    <>
      <Header />
      <main>
        <div className="my-7 grid place-items-center mx-auto max-w-[1560px] px-6 w-full">
          <h2 className="text-2xl md:text-3xl font-bold mb-2 text-center">
            Contact Us
          </h2>
          <p className="text-sm md:text-lg mb-7 text-center">
            Any questions? Suggestions? We would love to hear!
          </p>
          <br />

          <form
            onSubmit={handleSubmit}
            className="bg-white rounded-xl md:rounded-2xl p-4 md:py-6 md:px-16 mx-auto max-w-[991px] w-full"
          >
            <div className="flex items-center md:gap-8 max-md:flex-wrap md:mb-8">
              <Input
                className="w-full md:flex-1"
                title="First Name*"
                name="fname"
                value={data?.fname}
                onChange={handleInputChange}
                placeholder="Sparks"
                required
              />
              <Input
                className="w-full md:flex-1"
                title="Last Name*"
                name="lname"
                value={data?.lname}
                onChange={handleInputChange}
                placeholder="Adeyanju"
                required
              />
            </div>
            <div className="flex items-center md:gap-8 max-md:flex-wrap md:mb-8">
              <Input
                className="w-full md:flex-1"
                title="Email*"
                type="email"
                name="email"
                value={data?.email}
                onChange={handleInputChange}
                placeholder="ade123@gmail.com"
                required
              />
              <Input
                className="w-full md:flex-1"
                title="Phone*"
                type="number"
                name="phone"
                value={data?.phone}
                onChange={handleInputChange}
                placeholder="09068114071"
                required
              />
            </div>
            <Textarea
              className="w-full md:flex-1 !mb-4 md:!mb-8"
              title="Message*"
              name="message"
              value={data?.message}
              onChange={handleInputChange}
              placeholder="Message"
              required
            />

            <Button className="!w-fit mx-auto block px-6">Send</Button>
          </form>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Contact;

