/* eslint-disable react/prop-types */
import React from "react";

interface YoutubeEmbedProps {
  url?: string;
}

const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({ url }) => {
  const match = url?.match(
    /(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/i,
  );
  const videoId = match ? match[1] : null;

  if (videoId) {
    return (
      <div className="relative overflow-hidden w-full pb-[56.25%]">
        <iframe
          className="absolute top-0 left-0 w-full h-full border-none"
          src={`https://www.youtube.com/embed/${videoId}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded YouTube"
        />
      </div>
    );
  }

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-600 hover:underline"
    >
      {url}
    </a>
  );
};

export default YoutubeEmbed;
