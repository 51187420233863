import React, { useState, useEffect } from "react";
import Api from "../../api/api"; // Adjust the path to your API file accordingly
import AdminHeader from "../../components/Admin/AdminHeader";
import Footer from "../../common/Footer";

const AdminCategoriesPage = () => {
  const [categories, setCategories] = useState<any[]>([]);
  const [newCategory, setNewCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editingCategoryId, setEditingCategoryId] = useState<string | null>(
    null,
  );
  const [editedCategoryName, setEditedCategoryName] = useState("");

  useEffect(() => {
    fetchCategories();
  }, []);

  // Fetch categories from the server
  const fetchCategories = async () => {
    setIsLoading(true);
    const res = await Api.getCategories(); // API call to fetch categories
    setCategories(res.data.data);
    setIsLoading(false);
  };

  // Handle adding a new category
  const handleAddCategory = async () => {
    if (!newCategory.trim()) {
      alert("Please enter a valid category name.");
      return;
    }
    const res = await Api.addCategory({ name: newCategory });
    if (res.status === 200) {
      setNewCategory(""); // Reset the input field
      fetchCategories(); // Refresh categories after adding
    }
  };

  // Handle updating a category
  const handleUpdateCategory = async (id: string) => {
    if (!editedCategoryName.trim()) {
      alert("Please enter a valid category name.");
      return;
    }

    const res = await Api.updateCategory({ id: id, name: editedCategoryName });
    if (res.status === 200) {
      setEditingCategoryId(null); // Stop editing mode
      fetchCategories(); // Refresh categories after update
    }
  };

  // Handle deleting a category
  const handleDeleteCategory = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      const res = await Api.deleteCategory(id);
      if (res.status === 200) {
        fetchCategories(); // Refresh categories after deleting
      }
    }
  };

  // Handle edit button click
  const handleEditClick = (category: any) => {
    setEditingCategoryId(category._id);
    setEditedCategoryName(category.name); // Set the current category name in the input
  };

  return (
    <>
      <AdminHeader />
      <main className="mx-auto max-w-[1560px] p-6 bg-gray-50 min-h-screen">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">
          Manage Categories
        </h2>

        {/* Add New Category */}
        <div className="mb-6 flex items-center gap-4">
          <input
            type="text"
            value={newCategory}
            onChange={e => setNewCategory(e.target.value)}
            placeholder="New Category Name"
            className="p-3 border border-gray-300 max-w-[300px] rounded-md w-full focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={handleAddCategory}
            className="bg-blue-600 text-white px-4 py-2 rounded-md shadow hover:bg-blue-700 transition"
          >
            Add Category
          </button>
        </div>

        {/* Categories Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg">
            <thead>
              <tr className="bg-gray-100 border-b">
                <th className="text-left p-4">Category Name</th>
                <th className="text-left p-4">Created At</th>
                <th className="text-right p-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td
                    colSpan={3}
                    className="p-4 text-center text-gray-500"
                  >
                    Loading categories...
                  </td>
                </tr>
              ) : categories.length > 0 ? (
                categories.map(category => (
                  <tr
                    key={category._id}
                    className="border-b"
                  >
                    <td className="p-4">
                      {editingCategoryId === category._id ? (
                        <input
                          type="text"
                          value={editedCategoryName}
                          onChange={e => setEditedCategoryName(e.target.value)}
                          className="p-2 border border-gray-300 rounded-md"
                        />
                      ) : (
                        category.name
                      )}
                    </td>
                    <td className="p-4">
                      {new Date(category.createdAt).toLocaleDateString()}
                    </td>
                    <td className="p-4 text-right flex justify-end gap-4">
                      {editingCategoryId === category._id ? (
                        <>
                          <button
                            onClick={() => handleUpdateCategory(category._id)}
                            className="text-green-600 hover:text-green-800 transition"
                          >
                            Save
                          </button>
                          <button
                            onClick={() => setEditingCategoryId(null)}
                            className="text-gray-600 hover:text-gray-800 transition"
                          >
                            Cancel
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => handleEditClick(category)}
                            className="text-blue-600 hover:text-blue-800 transition"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => handleDeleteCategory(category._id)}
                            className="text-red-600 hover:text-red-800 transition"
                          >
                            Delete
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={3}
                    className="p-4 text-center text-gray-500"
                  >
                    No categories found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default AdminCategoriesPage;
