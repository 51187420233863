import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Item from "../components/Items/Item";
import Api from "../api/api";
import { useAuth } from "../context/auth";
import Button from "../common/Button";
import Modal from "react-modal";
import { FaUserCog, FaPlusCircle } from "react-icons/fa";
import avatar from "../assets/avatar.jpg";
Modal.setAppElement("#root");

const AddItemsToCollectionPage = () => {
  const { id: idParam } = useParams<any>();
  const { user } = useAuth();
  const history = useHistory();
  const [collection, setCollection] = useState<any>();
  const [items, setItems] = useState<any[]>([]);
  const [userItemsNotInCollection, setUserItemsNotInCollection] = useState<
    any[]
  >([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [seller, setSeller] = useState<any>();
  const [pageCount, setPageCount] = useState(0);
  const perPage = 48;
  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const handlePageClick = (event: any) => {
    const newOffset =
      (event.selected * perPage) % userItemsNotInCollection?.length;
    setItemOffset(newOffset);
  };

  async function getSeller(uid: string) {
    const res: any = await Api.getUser(uid);

    if (res.status === 200) {
      setSeller(res.data.data);
    }
  }

  async function getCollectionItems() {
    const params = { collectionId: idParam, userId: user?._id };
    const data = {
      id: user?._id,
      //has to be updated
      token: user?._id,
    };
    const res: any = await Api.getCollection(params);
    if (res.status === 200) {
      const fetchedItems: any = res?.data?.data;
      getSeller(fetchedItems.user);
      setCollection(fetchedItems);
      const userItemsRes: any = await Api.getUserItem(data);

      const userItems = userItemsRes?.data?.data;
      const notInCollection = userItems?.filter(
        (item: any) =>
          !fetchedItems.items.some(
            (collItem: any) => collItem._id === item._id,
          ),
      );
      setPageCount(Math.ceil(userItems?.length / perPage));
      setUserItemsNotInCollection(notInCollection);
    }
  }

  useEffect(() => {
    getCollectionItems();
  }, [idParam, user?._id]);

  const toggleSelectItem = (itemId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedItems(prevSelectedItems =>
      prevSelectedItems.includes(itemId)
        ? prevSelectedItems?.filter(id => id !== itemId)
        : [...prevSelectedItems, itemId],
    );
  };

  const handleAddItemsToCollection = async () => {
    const data = {
      collectionId: idParam,
      items: [...selectedItems, ...collection.items],
    };
    await Api.updateCollection(data);
    history.push(`/collection/${idParam}`);
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempSearchQuery(e.target.value);
  };

  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setSearchQuery(tempSearchQuery);
    }
  };

  const filteredItems = userItemsNotInCollection?.filter(item => {
    const query = searchQuery.toLowerCase();
    return (
      item.name.toLowerCase().includes(query) ||
      item.year?.toString().includes(query) ||
      item.categories?.some((category: any) =>
        category.name.toLowerCase().includes(query),
      ) ||
      item.countries?.some((country: string) =>
        country.toLowerCase().includes(query),
      )
    );
  });

  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <div className="flex justify-between items-center mb-8">
              <div className="flex items-center gap-4 max-w-[250px]">
                <div className="bg-white p-3 md:p-4 rounded-2xl border border-black w-full">
                  <div className="flex items-center gap-3">
                    <img
                      src={seller?.avatar || avatar}
                      alt="Seller"
                      className="w-14 h-14 md:w-20 md:h-20 rounded-full object-cover"
                    />
                    <div>
                      <p className="text-lg md:text-xl font-semibold mb-1">
                        {seller?.fname} {seller?.lname}
                      </p>
                      <p className="text-sm md:text-base line-clamp-2">
                        {seller?.about}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-grow text-center">
                <p className="text-3xl font-bold">{collection?.name}</p>
              </div>

              {selectedItems?.length > 0 && (
                <div className="relative">
                  <Button
                    className="!w-fit flex items-center gap-2"
                    onClick={handleAddItemsToCollection}
                  >
                    <FaPlusCircle fill="white" />
                    Add
                  </Button>
                </div>
              )}
            </div>

            <div
              className="mb-8"
              style={{ display: "flex" }}
            >
              <input
                type="text"
                value={tempSearchQuery}
                onChange={handleSearchChange}
                onKeyDown={handleSearchKeyPress}
                placeholder="Search items..."
                className="border border-gray-300 rounded-md p-2"
                style={{
                  width: "250px", // Set the width to 200px
                  padding: "8px 12px",

                  borderRadius: "5px",
                  fontSize: "14px",
                  outline: "none",
                }}
              />
            </div>
            <p className="text-lg text-gray-500">
              {userItemsNotInCollection?.length} items available to add
            </p>

            {/* Items Grid */}
            <div className="grid gap-4 grid-cols-3 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6">
              {filteredItems
                ?.slice(itemOffset, itemOffset + perPage)
                .map((item, idx) => (
                  <div
                    key={item._id || idx}
                    onClick={event => toggleSelectItem(item._id, event)}
                    className={`cursor-pointer ${
                      selectedItems.includes(item._id)
                        ? "border-4 border-green-500"
                        : ""
                    }`}
                  >
                    <Item
                      isPinned={item.pinned}
                      isFeatured={item.pinned}
                      isCountry={item.isCountry}
                      isSeller={true}
                      user_data={item.uid}
                      isCategory={true}
                      name={item.name}
                      uid={item.uid._id}
                      isAddItem={true}
                      description={item.description}
                      country={item.countries}
                      photos={item.photos}
                      category={item.categories}
                      currency={item.currency}
                      price={item.price}
                      year={item?.year}
                      hidden={item?.hidden}
                      id={item?._id}
                      createdAt={item?.createdAt}
                      updatedAt={item?.createdAt}
                      onPinToggle={() => {}}
                      isSelectable={true}
                      onClick={e => toggleSelectItem(item._id, e)}
                    />
                  </div>
                ))}
            </div>
          </div>

          <br />
          <div>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="flex flex-wrap justify-center mt-4"
              pageClassName="mx-2"
              pageLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              previousClassName="mx-2"
              previousLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              nextClassName="mx-2"
              nextLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              breakClassName="mx-2"
              breakLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              activeClassName="bg-slate-200 rounded-full "
              activeLinkClassName="font-semibold bg-slate-200"
            />
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default AddItemsToCollectionPage;
