import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { IconType } from "react-icons";
import { FaCoins, FaLocationDot } from "react-icons/fa6";
import ReactPaginate from "react-paginate";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Item from "../components/Items/Item";
import Filters from "../components/Items/Filters";
import { ISelectOption } from "../types/common";
import Dots from "../assets/dots.svg";
import avatar from "../assets/avatar.jpg";
import { useAuth } from "../context/auth";
import Api from "../api/api";
import { IUser } from "../types/auth";
import { countries } from "../utils/constant";
import { FaSpinner } from "react-icons/fa";
const Items = () => {
  const location = useLocation();
  const q = new URLSearchParams(location.search);
  const hasSeller = q.get("seller");
  const searchQuery = q.get("q") || "";
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [userData, setUserData] = useState<IUser | undefined>();
  const [items, setItems] = useState<any>([]);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);

  const [itemOffset, setItemOffset] = useState(0);
  const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const [showPage, setShowPage] = useState(false);
  const perPage = 72;
  const pageCount = Math.ceil(filteredItems.length / perPage);
  const [exchangeRates, setExchangeRates] = useState<any>({});
  const [selectedCategories, setSelectedCategories] = useState<
    ISelectOption[] | any
  >([]);
  const [selectedLocations, setSelectedLocations] = useState<
    ISelectOption[] | any
  >([]);
  const [selectedCountries, setSelectedCountries] = useState<
    ISelectOption[] | any
  >([]);
  const [locationOptions, setLocationOptions] = useState<
    ISelectOption[] | any[]
  >(["Everywhere"]);
  const [_location, setLocation] = useState("");
  const [currency, setCurrency] = useState("USD");

  const [search, setSearch] = useState("");
  const [years, setYears] = useState<{
    min: string | number;
    max: string | number;
  }>({ min: "", max: "" });

  const [prices, setPrices] = useState<{
    min: string | number;
    max: string | number;
  }>({ min: "", max: "" });

  const isSeller = useMemo(
    () => user?._id === hasSeller,
    [location.search, user],
  );

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * perPage) % filteredItems.length;
    setItemOffset(newOffset);
  };
  const getUser = useCallback(async (id: string) => {
    try {
      const res = await Api.getUser(id);
      if (res.status === 200) {
        setUserData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const populateLocationOptions = useCallback(() => {
    const uniqueCountries = Array.from(
      new Set(items.map(item => item.uid?.country as string)),
    ).filter(Boolean);

    const countryOptions: ISelectOption[] = countries
      .filter(country => uniqueCountries.includes(country.value))
      .map(country => ({
        value: country.value,
        label: country.label,
      }));
    countryOptions.unshift({ value: "", label: "Everywhere" });
    setLocationOptions(countryOptions);
  }, [items]);

  const handlePinToggle = async (itemId: string, isPinned: boolean) => {
    try {
      await Api.updateItem({ itemId: itemId, pinned: !isPinned });

      await getAllItems();
    } catch (error) {
      console.error("Failed to update pinned status", error);
    }
  };
  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      setShowPage(true);
    }, 500);

    setDebounceTimeout(timeout);

    return () => clearTimeout(timeout);
  }, [
    searchQuery,
    items,
    selectedCategories,
    selectedLocations,
    currency,
    filteredItems,
  ]);
  useEffect(() => {
    if (hasSeller) {
      getUser(hasSeller);
    }
  }, [location.search]);
  useEffect(() => {
    getAllItems();
  }, [user]);
  const getExchangeRates = async () => {
    try {
      const response = await fetch(
        "https://api.exchangerate-api.com/v4/latest/USD",
      );
      const data = await response.json();
      setExchangeRates(data.rates);
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
    }
  };
  async function getAllItems() {
    const res: any = await Api.getAllItem(null);
    if (res.status == 200) {
      const sortedItems = res.data.data.sort((a: any, b: any) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });
      console.log(res.data.data);
      const groupedByDate = groupByDate(sortedItems);
      const finalItems: any[] = [];

      for (const date in groupedByDate) {
        const groupedItems = groupBySeller(groupedByDate[date]);
        finalItems.push(...alternateSellers(groupedItems));
      }
      if (isSeller) {
        setItems(finalItems.slice(0, 30));
        return;
      }
      setItems(finalItems);
    }
  }

  const groupByDate = (ite: any[]): Record<string, any[]> => {
    return ite.reduce((acc: Record<string, any[]>, item: any) => {
      const dateKey = new Date(item.updatedAt).toISOString().split("T")[0];
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(item);
      return acc;
    }, {});
  };

  const groupBySeller = (ite: any[]): Record<string, any[]> => {
    return ite.reduce((acc: Record<string, any[]>, item: any) => {
      const sellerId = item.uid._id;
      if (!acc[sellerId]) {
        acc[sellerId] = [];
      }
      acc[sellerId].push(item);
      return acc;
    }, {});
  };

  const alternateSellers = (groupedItems: Record<string, any[]>): any[] => {
    const sellerIds = Object.keys(groupedItems);
    const alternatedItems: any[] = [];

    let index = 0;

    while (Object.values(groupedItems).some(group => group.length > 0)) {
      const sellerId = sellerIds[index % sellerIds.length];
      const sellerItems = groupedItems[sellerId];
      if (sellerItems.length > 0) {
        alternatedItems.push(sellerItems.shift()!);
      }
      index++;
    }

    return alternatedItems;
  };
  const convertCurrency = (
    amount: string | number,
    fromCurrency: string,
    toCurrency: string,
  ): number => {
    if (
      !exchangeRates ||
      !exchangeRates[fromCurrency] ||
      !exchangeRates[toCurrency]
    ) {
      return typeof amount === "number" ? amount : parseFloat(amount);
    }

    const convertedAmount =
      ((typeof amount === "number" ? amount : parseFloat(amount)) /
        exchangeRates[fromCurrency]) *
      exchangeRates[toCurrency];

    return parseFloat(convertedAmount.toFixed(2));
  };
  useEffect(() => {
    getExchangeRates();
  }, []);
  useEffect(() => {
    setLoading(true);
    const convertAndFilterItems = () => {
      let updatedItems = items.map(item => ({
        ...item,
        convertedPrice: convertCurrency(item.price, item.currency, currency),
        selectedCurrency: currency,
      }));

      // Apply existing filters after currency conversion
      if (selectedLocations.length > 0) {
        updatedItems = updatedItems.filter(item =>
          selectedLocations.includes(item.uid?.country),
        );
      }

      setFilteredItems(updatedItems);
    };

    convertAndFilterItems();

    setLoading(false);
  }, [currency, items, selectedLocations]);

  useEffect(() => {
    let updatedItems = items;

    if (selectedLocations) {
      updatedItems = items.filter(
        item => item.uid?.country === selectedLocations,
      );
    }

    setFilteredItems(updatedItems);
  }, [selectedLocations, items]);

  useEffect(() => {
    let updatedItems = items;

    if (selectedLocations.length > 0) {
      updatedItems = items.filter(item =>
        selectedLocations.includes(item.uid?.country),
      );
    }

    setFilteredItems(updatedItems);
  }, [selectedLocations, items]);

  useEffect(() => {
    populateLocationOptions();
  }, [items, populateLocationOptions]);
  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            {!hasSeller ? (
              <div className="flex items-center gap-2 mb-4 text-primary">
                {/* Location Filter */}
                <div className="flex items-center gap-2">
                  <FaLocationDot
                    size={22}
                    fill="var(--primary)"
                  />
                  <span className="sm:hidden md:block font-medium text-sm text-primary">
                    View results from
                  </span>
                  <select
                    value={selectedLocations}
                    onChange={e => setSelectedLocations(e.target.value)}
                    className="rounded-md outline-none border border-primary px-3 py-1 text-sm"
                  >
                    {locationOptions.map((itm, idx) => (
                      <option
                        key={idx}
                        value={itm.value}
                      >
                        {itm.label}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Currency Filter */}
                <div className="flex items-center gap-2 ml-4">
                  <FaCoins
                    size={22}
                    fill="var(--primary)"
                  />
                  <span className="sm:hidden md:block font-medium text-sm text-primary">
                    View price in
                  </span>
                  <select
                    value={currency}
                    onChange={e => setCurrency(e.target.value)}
                    className="rounded-md outline-none border border-primary px-3 py-1 text-sm"
                  >
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="ILS">ILS</option>
                  </select>
                </div>
              </div>
            ) : (
              <div className="flex justify-end mb-2">
                <div className="bg-white border border-black rounded-xl p-3 md:p-4 flex items-center gap-3 md:gap-4 w-fit">
                  <img
                    src={avatar}
                    className="size-[50px] md:size-[80px]"
                    alt="Seller"
                  />
                  <div>
                    <p className="font-bold md:text-xl mb-1">
                      {/* {userData?.fname} {userData?.lname} */}
                      Full Name
                    </p>
                    <span className="text-sm">World banknotes</span>
                  </div>
                </div>
              </div>
            )}

            <p className="text-xl md:text-2xl font-bold mb-3 text-center">
              {!hasSeller ? "All Items" : "User's Items"}
            </p>

            <Filters
              selectedCurrency={currency}
              setFilteredItems={setFilteredItems}
              items={items}
              filteredItems={filteredItems}
              search={search}
              setSearch={setSearch}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              selectedCountries={selectedCountries}
              setSelectedCountries={setSelectedCountries}
              years={years}
              setYears={setYears}
              prices={prices}
              setPrices={setPrices}
              selectedLocations={selectedLocations}
              exchangeRates={exchangeRates}
              convertedPrices={filteredItems.map(i => ({
                id: i._id,
                convertedPrice: convertCurrency(i.price, i.currency, currency),
                currency: i.selectedCurrency || currency,
              }))}
            />
            {loading || !showPage ? (
              <div className="flex justify-center items-center py-10">
                <FaSpinner className="animate-spin text-3xl text-primary" />
              </div>
            ) : (
              <div className="grid gap-4 grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                {filteredItems
                  .slice(itemOffset, itemOffset + perPage)
                  .map((item, idx) => (
                    <Item
                      key={item._id || idx}
                      isPinned={item.pinned}
                      isFeatured={item.pinned}
                      isCountry={item.isCountry}
                      isSeller={false}
                      name={item.name}
                      uid={item.uid._id}
                      user_data={item.uid}
                      description={item.description}
                      country={item.countries}
                      photos={item.photos}
                      category={item.categories}
                      currency={item.selectedCurrency}
                      price={convertCurrency(
                        item.price,
                        item.currency,
                        currency,
                      )}
                      year={item?.year}
                      hidden={item?.hidden}
                      id={item?._id}
                      createdAt={item?.createdAt}
                      updatedAt={item?.createdAt}
                      onPinToggle={handlePinToggle}
                      convertedPrice={convertCurrency(
                        item.price,
                        item.currency,
                        currency,
                      )}
                    />
                  ))}
              </div>
            )}
          </div>

          <br />
          <div>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="flex flex-wrap justify-center mt-4"
              pageClassName="mx-2"
              pageLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              previousClassName="mx-2"
              previousLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              nextClassName="mx-2"
              nextLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              breakClassName="mx-2"
              breakLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              activeClassName="bg-slate-200 rounded-full "
              activeLinkClassName="font-semibold bg-slate-200"
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

const Select = ({
  Icon,
  title,
  options,
  value,
  setValue,
}: {
  Icon: IconType;
  title: string;
  options: ISelectOption[];
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <>
      <Icon
        size={22}
        fill="var(--primary)"
      />

      <span className="font-medium text-sm text-primary">{title}</span>
      <select
        value={value}
        onChange={e => setValue(e.target.value)}
        className="rounded-md outline-none border border-primary px-3 py-2 text-sm"
      >
        {options.map((itm, idx) => (
          <option
            key={idx}
            value={itm.value}
          >
            {itm.label}
          </option>
        ))}
      </select>
    </>
  );
};
export default Items;
